.tab-style-01 {
	.nav-tabs {
		border: none;
		.nav-item {
			+ .nav-item {
				padding-left: 20px;
			}
		}
		.nav-link {
			padding: 0;
			border: none;
			font-weight: 600;
			color: var(--#{$prefix}body-color);
			position: relative;
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0px;
				height: 2px;
				background: var(--#{$prefix}primary);
				transition: ease all 0.35s;
			}
			&:hover {
				color: var(--#{$prefix}primary);
			}
			&.active {
				color: var(--#{$prefix}dark);
				&:after {
					width: 100%;
				}
			}
		}
	}
}