.shadow-xs {
	box-shadow: $box-shadow-xs !important;	
}

.shadow-xl {
	box-shadow: $box-shadow-xl !important;	
}

.shadow-xxl {
	box-shadow: $box-shadow-xxl !important;	
}
