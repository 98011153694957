img {
	max-width: 100%;
	height: auto;
}

.object-fit {
    object-fit: cover;
}

.mw-auto {
    max-width: inherit;
}

.grayscale {
     filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}