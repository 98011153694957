.product-detail {
	.pd-rating {
		color: var(--#{$prefix}warning);
	}
	.pd-price {
		.price {
			font-size: 1.4rem;
			color: var(--#{$prefix}dark);
			font-weight: 500;
		}
		.pp-offer {
			color: var(--#{$prefix}danger);
		}
	}
}

.product-detail-collapse {
	margin-top: 20px;
	border-top: 1px solid var(--#{$prefix}gray-300);
	.pd-collapse-row {
		border-bottom: 1px solid var(--#{$prefix}gray-300);
		.pd-collapse-h5 {
			padding: 10px 0;
			font-weight: 600;
			color: $dark;
			display: flex;
			i {
				margin-left: auto;
			}
			&[aria-expanded="false"] {
				i {
					&:before {
						content: "\f282";
					}
				}
			}
		}
	}
	.pd-collapse-box {
		padding: 15px 0;
		border-top: 1px solid var(--#{$prefix}gray-300);
	}
}

.product-gallery {
	display: flex;
	margin-bottom: auto;
	.product-slider {
	    flex: 0 0 calc(100% - 120px);
	    margin-left: 20px;
	    max-width: calc(100% - 120px);
	    order: 2;
	    border: 1px solid $gray-300;
	    @include media-breakpoint-down(md) {
	    	flex: 0 0 calc(100% - 60px);
	    	margin-left: 10px;
	    	max-width: calc(100% - 60px);
	    }
	}
	.gallery-link {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 40px;
		height: 40px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: $white;
		background: rgba($dark, 0.8);
	}
	.product-thumb {
		.swiper-slide {
			img {
				border: 1px solid $gray-300;
				cursor: pointer;
				width: 100%;
			}
			&.swiper-slide-thumb-active {
				img {
					border-color: $primary;
				}
			}
		}
	}
	.product-thumb {
		flex: 0 0 100px;
		max-width: 100px;
		@include media-breakpoint-down(md) {
			flex: 0 0 50px;
			max-width: 50px;
		}
		.slick-arrow {
			border: 1px solid $gray-300;
		}
	}
}


.pd-title {
	font-weight: 600;
	color: var(--#{$prefix}dark);
}