:root {
    --swiper-theme-color: #{$primary};
}

.swiper-arrow-style-01 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    color: var(--#{$prefix}gray-100);
    z-index: 1;
    text-align: center;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    transition: ease all 0.35s;
    font-size: 20px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: var(--#{$prefix}white);
    &.swiper-button-disabled {
        opacity: 0.5 !important;
        pointer-events: none;
    }

    &:hover {
        background: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
    }

    .swiper-hover-arrow & {
        opacity: 0;
    }

    .swiper-hover-arrow:hover & {
        opacity: 1;
    }

    &.swiper-prev {
        left: 10px;
        right: auto;
    }
}


.swiper-arrow-style-02 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: var(--#{$prefix}gray-100);
    color: var(--#{$prefix}white);
    z-index: 1;
    text-align: center;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: ease all 0.35s;
    font-size: 20px;
    width: 25px;
    height: 40px;

    &.swiper-button-disabled {
        .swiper-hover-arrow:hover & {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &:hover {
        background: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
    }

    .swiper-hover-arrow & {
        opacity: 0;
    }

    .swiper-hover-arrow:hover & {
        opacity: 1;
    }

    &.swiper-next {
        right: 0;
    }

    &.swiper-prev {
        left: 0;
    }
}

.swiper-arrow-style-03 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    color: var(--#{$prefix}white);
    background: var(--#{$prefix}dark);
    z-index: 1;
    text-align: center;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    right: 0;
    transition: ease all 0.35s;
    font-size: 18px;
    width: 33px;
    height: 33px;
    border-radius: 50%;

    &.swiper-button-disabled {
        opacity: 0.5 !important;
        pointer-events: none;
        .swiper-hover-arrow & {
            opacity: 0 !important;
        }
        .swiper-hover-arrow:hover & {
            opacity: 0.5 !important;
        }
    }

    &:hover {
        background: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
    }

    .swiper-hover-arrow & {
        opacity: 0;
    }

    .swiper-hover-arrow:hover & {
        opacity: 1;
    }

    &.swiper-prev {
        left: 0;
        right: auto;
    }

    .swiper-vertical  & {
        margin: auto;
        width: 40px;
        height: 20px;
        &.swiper-next {
            bottom: auto;
            top: 0;
            left: 0;
            right: 0;
        }
        &.swiper-prev {
            bottom: 0;
            top: auto;
            left: 0;
            right: 0;
        }
    }
}


.pd-swiper-arrow {
    position: absolute;
    height: 25px;
    background: var(--#{$prefix}dark);
    color: var(--#{$prefix}white);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    &.swiper-next {
        bottom: 0;
        top: auto;
    }
    &.swiper-button-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.swiper-pagination {
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid var(--#{$prefix}primary);
        background: transparent;
        opacity: 1;
        &.swiper-pagination-bullet-active,
        &:hover {
            background: var(--#{$prefix}primary);
        }
    }
    &.swiper-pagination-white {
        .swiper-pagination-bullet {
            border: 1px solid var(--#{$prefix}white);
            background: transparent;
            &.swiper-pagination-bullet-active,
            &:hover {
                background: var(--#{$prefix}white);
            }
        }
    }
}

.swiper {
    &.swiper-no-scroll {
        overflow: inherit;
    }   
}