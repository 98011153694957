/*----------------------------------------------------------------------
  * Crizo - Multipurpose Responsive Template
  * Copyright 2022 uiocean.com by pxdraft
------------------------------------------------------------------------*/

// eLearn setup
@import '../vendor/bootstrap/scss/functions';


// Custom setup
@import 'core/custom/user-variables';

// ecommerce setup
@import 'core/ecommerce/ecommerce-variables';

// Theme
//@import "core/variables";
@import "core/components/utilitie";
@import "core/fonts";


// Vendor
@import '../vendor/bootstrap/scss/bootstrap';
@import '../vendor/bootstrap/icon/bootstrap-icons.css';
@import '../vendor/feather/feather.css';
@import '../vendor/fontawesome/css/all.min.css';
@import '../vendor/swiper/swiper-bundle.min.css';
@import '../vendor/magnific/magnific-popup.css';


@import "core/root";


// eCommerce Header
@import "core/ecommerce/header/header-01";
@import "core/ecommerce/header/navbar";

// eCommerce Footer
@import "core/footer/footer";



@import "core/components/spinner";
@import "core/components/effects";
@import "core/components/wrapper";
@import "core/components/button";
@import "core/components/card";
@import "core/components/background";
@import "core/components/section";
@import "core/components/img";
@import "core/components/icon";
@import "core/components/shadow";
@import "core/components/title";
@import "core/components/avatar";
//@import "core/components/swiper";
@import "core/components/breadcrumb";
@import "core/components/form";
@import "core/components/link";
@import "core/components/hover";
@import "core/components/dropdown";
@import "core/components/height";
@import "core/components/ellipsis";
@import "core/components/mark";
@import "core/components/accordion";
@import "core/components/tabs";
@import "core/components/article";


// eCommerce

@import "core/ecommerce/swiper";
@import "core/ecommerce/count-down";
@import "core/ecommerce/shop/product-card";
@import "core/ecommerce/shop/shop";
@import "core/ecommerce/shop/shop-detail";



// Custom styles (User's file)
@import "core/custom/styles";