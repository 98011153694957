.effect-section {
    position: relative;
    overflow: hidden;

    .svg-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
    }

    .svg-style-top-right {
        position: absolute;
        top: -5vw;
        right: -5vw;
        width: 30vw;
        height: 30vw;
    }

    .svg-style-bottom-left {
        position: absolute;
        bottom: -5vw;
        left: -5vw;
        width: 25vw;
        height: 25vw;
        pointer-events: none;
    }

    svg {
        path {
            fill: currentColor;
        }
    }
}


.laptop-screen {
    position: relative;
	.laptop-screen-inner {
	    position: absolute;
	    width: 79.3%;
	    left: 10.5%;
	    top: 7.6%;
	    height: 80.5%;
	    border: 1px solid var(--#{$prefix}gray-300);
	    border-radius: 10px;
	    overflow: hidden;
		img {
		    object-fit: cover;
		}
	}
}



.mobile-screen {
    position: relative;
    border: 10px solid var(--#{$prefix}gray-300);
    border-radius: 28px;
	.mobile-hidden {
	    opacity: 0;
	    visibility: hidden;
	}
	.mobile-screen-in {
	    position: absolute;
	    top: 5px;
	    left: 5px;
	    right: 5px;
	    bottom: 5px;
	    overflow: hidden;
	    border-radius: 18px;
		img {
		    width: 100%;
		    height: 100%;
		    object-fit: cover;
		}
	}
}


