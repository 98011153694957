.section-heading {
    @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 2.25rem;
    }
    margin-bottom: 1.5rem;
}

.sm-title-1 {
    position: relative;
    padding-bottom: 0.8rem;;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 40px;
        height: 2px;
        background: var(--#{$prefix}primary);
    }
}