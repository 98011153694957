/*----------------------------------------------------------------------
  * Crizo - Multipurpose Responsive Template
  * Copyright 2022 uiocean.com by pxdraft
------------------------------------------------------------------------*/
/*$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px
) !default;*/
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/*!
   * Bootstrap  v5.2.1 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
@import url(../vendor/bootstrap/icon/bootstrap-icons.css);
@import url(../vendor/feather/feather.css);
@import url(../vendor/fontawesome/css/all.min.css);
@import url(../vendor/swiper/swiper-bundle.min.css);
@import url(../vendor/magnific/magnific-popup.css);
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #737373;
  --bs-gray-dark: #404040;
  --bs-gray-100: #fafafa;
  --bs-gray-200: #f0f0f0;
  --bs-gray-300: #e6e6e6;
  --bs-gray-400: #dbdbdb;
  --bs-gray-500: #d1d1d1;
  --bs-gray-600: #737373;
  --bs-gray-700: #595959;
  --bs-gray-800: #404040;
  --bs-gray-900: #262626;
  --bs-primary: #B96F4A;
  --bs-secondary: #FF6F61;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #F8F9FA;
  --bs-white: #fff;
  --bs-dark: #212529;
  --bs-gray-100: #fafafa;
  --bs-gray-200: #f0f0f0;
  --bs-gray-300: #e6e6e6;
  --bs-gray-400: #dbdbdb;
  --bs-gray-500: #d1d1d1;
  --bs-gray-600: #737373;
  --bs-gray-700: #595959;
  --bs-gray-800: #404040;
  --bs-gray-900: #262626;
  --bs-primary-rgb: 185, 111, 74;
  --bs-secondary-rgb: 255, 111, 97;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-white-rgb: 255, 255, 255;
  --bs-dark-rgb: 33, 37, 41;
  --bs-gray-100-rgb: 250, 250, 250;
  --bs-gray-200-rgb: 240, 240, 240;
  --bs-gray-300-rgb: 230, 230, 230;
  --bs-gray-400-rgb: 219, 219, 219;
  --bs-gray-500-rgb: 209, 209, 209;
  --bs-gray-600-rgb: 115, 115, 115;
  --bs-gray-700-rgb: 89, 89, 89;
  --bs-gray-800-rgb: 64, 64, 64;
  --bs-gray-900-rgb: 38, 38, 38;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 108, 117, 125;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Rubik", -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.7;
  --bs-body-color: #6C757D;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e6e6e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.75rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #B96F4A;
  --bs-link-hover-color: #94593b;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #152C5B; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: none; }
  a:hover {
    color: var(--bs-link-hover-color); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color); }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #737373;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400; }
  @media (min-width: 1200px) {
    .lead {
      font-size: 1.5rem; } }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.3875rem + 1.65vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.625rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #737373; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #737373; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem; }

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem; }

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem; }

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem; }

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem; }

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem; }

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem; }

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem; }

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem; }

.g-10,
.gx-10 {
  --bs-gutter-x: 5rem; }

.g-10,
.gy-10 {
  --bs-gutter-y: 5rem; }

.g-11,
.gx-11 {
  --bs-gutter-x: 6rem; }

.g-11,
.gy-11 {
  --bs-gutter-y: 6rem; }

.g-12,
.gx-12 {
  --bs-gutter-x: 9rem; }

.g-12,
.gy-12 {
  --bs-gutter-y: 9rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem; }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 2.5rem; }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 2.5rem; }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3rem; }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3rem; }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 3.5rem; }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 3.5rem; }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 4rem; }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 4rem; }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 5rem; }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 5rem; }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 6rem; }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 6rem; }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 9rem; }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 9rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem; }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 2.5rem; }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 2.5rem; }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3rem; }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3rem; }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 3.5rem; }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 3.5rem; }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 4rem; }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 4rem; }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 5rem; }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 5rem; }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 6rem; }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 6rem; }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 9rem; }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 9rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem; }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 2.5rem; }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 2.5rem; }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3rem; }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3rem; }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 3.5rem; }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 3.5rem; }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 4rem; }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 4rem; }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 5rem; }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 5rem; }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 6rem; }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 6rem; }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 9rem; }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 9rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem; }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 2.5rem; }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 2.5rem; }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3rem; }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3rem; }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 3.5rem; }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 3.5rem; }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 4rem; }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 4rem; }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 5rem; }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 5rem; }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 6rem; }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 6rem; }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 9rem; }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 9rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem; }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 2.5rem; }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 2.5rem; }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3rem; }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3rem; }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 3.5rem; }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 3.5rem; }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 4rem; }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 4rem; }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 5rem; }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 5rem; }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 6rem; }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 6rem; }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 9rem; }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 9rem; } }

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: 2px solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #f1e2db;
  --bs-table-border-color: #d9cbc5;
  --bs-table-striped-bg: #e5d7d0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d9cbc5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dfd1cb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #ffe2df;
  --bs-table-border-color: #e6cbc9;
  --bs-table-striped-bg: #f2d7d4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cbc9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd1ce;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #F8F9FA;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
  color: #262626; }

.col-form-label {
  padding-top: calc(0.575rem + 1px);
  padding-bottom: calc(0.575rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
  color: #262626; }

.col-form-label-lg {
  padding-top: calc(0.825rem + 1px);
  padding-bottom: calc(0.825rem + 1px);
  font-size: 1rem; }

.col-form-label-sm {
  padding-top: calc(0.425rem + 1px);
  padding-bottom: calc(0.425rem + 1px);
  font-size: 0.88rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #737373; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.575rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.7;
  color: #6C757D;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #6C757D;
    background-color: #fff;
    border-color: #dcb7a5;
    outline: 0;
    box-shadow: unset; }
  .form-control::-webkit-date-and-time-value {
    height: 1.7em; }
  .form-control::placeholder {
    color: #737373;
    opacity: 1; }
  .form-control:disabled {
    background-color: #f0f0f0;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.575rem 1rem;
    margin: -0.575rem -1rem;
    margin-inline-end: 1rem;
    color: #6C757D;
    background-color: #f0f0f0;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #e4e4e4; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.575rem 0;
  margin-bottom: 0;
  line-height: 1.7;
  color: #6C757D;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.7em + 0.85rem + 2px);
  padding: 0.425rem 1rem;
  font-size: 0.88rem; }
  .form-control-sm::file-selector-button {
    padding: 0.425rem 1rem;
    margin: -0.425rem -1rem;
    margin-inline-end: 1rem; }

.form-control-lg {
  min-height: calc(1.7em + 1.65rem + 2px);
  padding: 0.825rem 1.75rem;
  font-size: 1rem; }
  .form-control-lg::file-selector-button {
    padding: 0.825rem 1.75rem;
    margin: -0.825rem -1.75rem;
    margin-inline-end: 1.75rem; }

textarea.form-control {
  min-height: calc(1.7em + 1.15rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.7em + 0.85rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.7em + 1.65rem + 2px); }

.form-control-color {
  width: 3rem;
  height: calc(1.7em + 1.15rem + 2px);
  padding: 0.575rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important; }
  .form-control-color.form-control-sm {
    height: calc(1.7em + 0.85rem + 2px); }
  .form-control-color.form-control-lg {
    height: calc(1.7em + 1.65rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: 0.575rem 3rem 0.575rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.7;
  color: #6C757D;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23404040' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #dbdbdb;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #dcb7a5;
    outline: 0;
    box-shadow: 0 0 0 0.125rem rgba(185, 111, 74, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 1rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #f0f0f0; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #6C757D; }

.form-select-sm {
  padding-top: 0.425rem;
  padding-bottom: 0.425rem;
  padding-left: 1rem;
  font-size: 0.88rem; }

.form-select-lg {
  padding-top: 0.825rem;
  padding-bottom: 0.825rem;
  padding-left: 1.75rem;
  font-size: 1rem; }

.form-check {
  display: block;
  min-height: 1.7rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.35em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #dcb7a5;
    outline: 0;
    box-shadow: unset; }
  .form-check-input:checked {
    background-color: #B96F4A;
    border-color: #B96F4A; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #B96F4A;
    border-color: #B96F4A;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 3.5em; }
  .form-switch .form-check-input {
    width: 3em;
    margin-left: -3.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dcb7a5'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 3.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -3.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.25rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, unset; }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, unset; }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #B96F4A;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #ead4c9; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e6e6e6;
    border-color: transparent; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #B96F4A;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #ead4c9; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e6e6e6;
    border-color: transparent; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #d1d1d1; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #d1d1d1; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 1rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 1rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.575rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.7;
  color: #6C757D;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f0f0;
  border: 1px solid #dbdbdb; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.825rem 1.75rem;
  font-size: 1rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.425rem 1rem;
  font-size: 0.88rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.88rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.7em + 1.15rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.2875rem) center;
  background-size: calc(0.85em + 0.575rem) calc(0.85em + 0.575rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.125rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.7em + 1.15rem);
  background-position: top calc(0.425em + 0.2875rem) right calc(0.425em + 0.2875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 5.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23404040' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 1rem center, center right 3rem;
    background-size: 16px 12px, calc(0.85em + 0.575rem) calc(0.85em + 0.575rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.125rem rgba(25, 135, 84, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.7em + 1.15rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #198754; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.125rem rgba(25, 135, 84, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.88rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.7em + 1.15rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.2875rem) center;
  background-size: calc(0.85em + 0.575rem) calc(0.85em + 0.575rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.125rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 1.15rem);
  background-position: top calc(0.425em + 0.2875rem) right calc(0.425em + 0.2875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 5.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23404040' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 1rem center, center right 3rem;
    background-size: 16px 12px, calc(0.85em + 0.575rem) calc(0.85em + 0.575rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.125rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.7em + 1.15rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.125rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.675rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.9rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.7;
  --bs-btn-color: #6C757D;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: unset;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  background-color: var(--bs-btn-bg);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  :not(.btn-check) + .btn:hover, .btn:first-child:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #B96F4A;
  --bs-btn-border-color: #B96F4A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #9d5e3f;
  --bs-btn-hover-border-color: #94593b;
  --bs-btn-focus-shadow-rgb: 196, 133, 101;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #94593b;
  --bs-btn-active-border-color: #8b5338;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #B96F4A;
  --bs-btn-disabled-border-color: #B96F4A; }

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #FF6F61;
  --bs-btn-border-color: #FF6F61;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d95e52;
  --bs-btn-hover-border-color: #cc594e;
  --bs-btn-focus-shadow-rgb: 255, 133, 121;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cc594e;
  --bs-btn-active-border-color: #bf5349;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #FF6F61;
  --bs-btn-disabled-border-color: #FF6F61; }

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754; }

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0baccc;
  --bs-btn-hover-border-color: #0aa2c0;
  --bs-btn-focus-shadow-rgb: 49, 210, 242;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0aa2c0;
  --bs-btn-active-border-color: #0a98b4;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #F8F9FA;
  --bs-btn-border-color: #F8F9FA;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F8F9FA;
  --bs-btn-disabled-border-color: #F8F9FA; }

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529; }

.btn-gray-100 {
  --bs-btn-color: #000;
  --bs-btn-bg: #fafafa;
  --bs-btn-border-color: #fafafa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fbfbfb;
  --bs-btn-hover-border-color: #fbfbfb;
  --bs-btn-focus-shadow-rgb: 213, 213, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fbfbfb;
  --bs-btn-active-border-color: #fbfbfb;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fafafa;
  --bs-btn-disabled-border-color: #fafafa; }

.btn-gray-200 {
  --bs-btn-color: #000;
  --bs-btn-bg: #f0f0f0;
  --bs-btn-border-color: #f0f0f0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f2f2f2;
  --bs-btn-hover-border-color: #f2f2f2;
  --bs-btn-focus-shadow-rgb: 204, 204, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f3f3f3;
  --bs-btn-active-border-color: #f2f2f2;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f0f0f0;
  --bs-btn-disabled-border-color: #f0f0f0; }

.btn-gray-300 {
  --bs-btn-color: #000;
  --bs-btn-bg: #e6e6e6;
  --bs-btn-border-color: #e6e6e6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #eaeaea;
  --bs-btn-hover-border-color: #e9e9e9;
  --bs-btn-focus-shadow-rgb: 196, 196, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ebebeb;
  --bs-btn-active-border-color: #e9e9e9;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e6e6e6;
  --bs-btn-disabled-border-color: #e6e6e6; }

.btn-gray-400 {
  --bs-btn-color: #000;
  --bs-btn-bg: #dbdbdb;
  --bs-btn-border-color: #dbdbdb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e0e0e0;
  --bs-btn-hover-border-color: #dfdfdf;
  --bs-btn-focus-shadow-rgb: 186, 186, 186;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e2e2e2;
  --bs-btn-active-border-color: #dfdfdf;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #dbdbdb;
  --bs-btn-disabled-border-color: #dbdbdb; }

.btn-gray-500 {
  --bs-btn-color: #000;
  --bs-btn-bg: #d1d1d1;
  --bs-btn-border-color: #d1d1d1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d8d8d8;
  --bs-btn-hover-border-color: #d6d6d6;
  --bs-btn-focus-shadow-rgb: 178, 178, 178;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dadada;
  --bs-btn-active-border-color: #d6d6d6;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d1d1d1;
  --bs-btn-disabled-border-color: #d1d1d1; }

.btn-gray-600 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #737373;
  --bs-btn-border-color: #737373;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #626262;
  --bs-btn-hover-border-color: #5c5c5c;
  --bs-btn-focus-shadow-rgb: 136, 136, 136;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5c5c5c;
  --bs-btn-active-border-color: #565656;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #737373;
  --bs-btn-disabled-border-color: #737373; }

.btn-gray-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #595959;
  --bs-btn-border-color: #595959;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4c4c4c;
  --bs-btn-hover-border-color: #474747;
  --bs-btn-focus-shadow-rgb: 114, 114, 114;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #474747;
  --bs-btn-active-border-color: #434343;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #595959;
  --bs-btn-disabled-border-color: #595959; }

.btn-gray-800 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #404040;
  --bs-btn-border-color: #404040;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #363636;
  --bs-btn-hover-border-color: #333333;
  --bs-btn-focus-shadow-rgb: 93, 93, 93;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333333;
  --bs-btn-active-border-color: #303030;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #404040;
  --bs-btn-disabled-border-color: #404040; }

.btn-gray-900 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #262626;
  --bs-btn-border-color: #262626;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #202020;
  --bs-btn-hover-border-color: #1e1e1e;
  --bs-btn-focus-shadow-rgb: 71, 71, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1e1e1e;
  --bs-btn-active-border-color: #1d1d1d;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #262626;
  --bs-btn-disabled-border-color: #262626; }

.btn-outline-primary {
  --bs-btn-color: #B96F4A;
  --bs-btn-border-color: #B96F4A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #B96F4A;
  --bs-btn-hover-border-color: #B96F4A;
  --bs-btn-focus-shadow-rgb: 185, 111, 74;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #B96F4A;
  --bs-btn-active-border-color: #B96F4A;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #B96F4A;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #B96F4A;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #FF6F61;
  --bs-btn-border-color: #FF6F61;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #FF6F61;
  --bs-btn-hover-border-color: #FF6F61;
  --bs-btn-focus-shadow-rgb: 255, 111, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #FF6F61;
  --bs-btn-active-border-color: #FF6F61;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #FF6F61;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FF6F61;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #F8F9FA;
  --bs-btn-border-color: #F8F9FA;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F8F9FA;
  --bs-btn-hover-border-color: #F8F9FA;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F8F9FA;
  --bs-btn-active-border-color: #F8F9FA;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #F8F9FA;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F8F9FA;
  --bs-gradient: none; }

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none; }

.btn-outline-gray-100 {
  --bs-btn-color: #fafafa;
  --bs-btn-border-color: #fafafa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fafafa;
  --bs-btn-hover-border-color: #fafafa;
  --bs-btn-focus-shadow-rgb: 250, 250, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fafafa;
  --bs-btn-active-border-color: #fafafa;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fafafa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fafafa;
  --bs-gradient: none; }

.btn-outline-gray-200 {
  --bs-btn-color: #f0f0f0;
  --bs-btn-border-color: #f0f0f0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f0f0f0;
  --bs-btn-hover-border-color: #f0f0f0;
  --bs-btn-focus-shadow-rgb: 240, 240, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f0f0f0;
  --bs-btn-active-border-color: #f0f0f0;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #f0f0f0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f0f0f0;
  --bs-gradient: none; }

.btn-outline-gray-300 {
  --bs-btn-color: #e6e6e6;
  --bs-btn-border-color: #e6e6e6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e6e6e6;
  --bs-btn-hover-border-color: #e6e6e6;
  --bs-btn-focus-shadow-rgb: 230, 230, 230;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e6e6e6;
  --bs-btn-active-border-color: #e6e6e6;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #e6e6e6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e6e6e6;
  --bs-gradient: none; }

.btn-outline-gray-400 {
  --bs-btn-color: #dbdbdb;
  --bs-btn-border-color: #dbdbdb;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dbdbdb;
  --bs-btn-hover-border-color: #dbdbdb;
  --bs-btn-focus-shadow-rgb: 219, 219, 219;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dbdbdb;
  --bs-btn-active-border-color: #dbdbdb;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #dbdbdb;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dbdbdb;
  --bs-gradient: none; }

.btn-outline-gray-500 {
  --bs-btn-color: #d1d1d1;
  --bs-btn-border-color: #d1d1d1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d1d1d1;
  --bs-btn-hover-border-color: #d1d1d1;
  --bs-btn-focus-shadow-rgb: 209, 209, 209;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d1d1d1;
  --bs-btn-active-border-color: #d1d1d1;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #d1d1d1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d1d1d1;
  --bs-gradient: none; }

.btn-outline-gray-600 {
  --bs-btn-color: #737373;
  --bs-btn-border-color: #737373;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #737373;
  --bs-btn-hover-border-color: #737373;
  --bs-btn-focus-shadow-rgb: 115, 115, 115;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #737373;
  --bs-btn-active-border-color: #737373;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #737373;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #737373;
  --bs-gradient: none; }

.btn-outline-gray-700 {
  --bs-btn-color: #595959;
  --bs-btn-border-color: #595959;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #595959;
  --bs-btn-hover-border-color: #595959;
  --bs-btn-focus-shadow-rgb: 89, 89, 89;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #595959;
  --bs-btn-active-border-color: #595959;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #595959;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #595959;
  --bs-gradient: none; }

.btn-outline-gray-800 {
  --bs-btn-color: #404040;
  --bs-btn-border-color: #404040;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #404040;
  --bs-btn-hover-border-color: #404040;
  --bs-btn-focus-shadow-rgb: 64, 64, 64;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #404040;
  --bs-btn-active-border-color: #404040;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #404040;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #404040;
  --bs-gradient: none; }

.btn-outline-gray-900 {
  --bs-btn-color: #262626;
  --bs-btn-border-color: #262626;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #262626;
  --bs-btn-hover-border-color: #262626;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #262626;
  --bs-btn-active-border-color: #262626;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #262626;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #262626;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #595959;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #B96F4A;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #B96F4A;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #737373;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 196, 133, 101;
  text-decoration: none; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.825rem;
  --bs-btn-padding-x: 1.75rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: 0.25rem; }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.425rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.88rem;
  --bs-btn-border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0.7rem;
  --bs-dropdown-padding-y: 0.7rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.95rem;
  --bs-dropdown-color: #6C757D;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-gray-300);
  --bs-dropdown-border-radius: 0.25rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.25rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-gray-300);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0px 2px 4px -1px rgba(var(--bs-dark-rgb), 0.1), 0px 2px 4px -2px rgba(var(--bs-dark-rgb), 0.1);
  --bs-dropdown-link-color: #262626;
  --bs-dropdown-link-hover-color: #B96F4A;
  --bs-dropdown-link-hover-bg: rgba(var(--bs-primary-rgb), 0.1);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #B96F4A;
  --bs-dropdown-link-disabled-color: #d1d1d1;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #737373;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.7rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.88rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #e6e6e6;
  --bs-dropdown-bg: #404040;
  --bs-dropdown-border-color: var(--bs-gray-300);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #e6e6e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-gray-300);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #B96F4A;
  --bs-dropdown-link-disabled-color: #d1d1d1;
  --bs-dropdown-header-color: #d1d1d1; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #737373;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #e6e6e6;
  --bs-nav-tabs-border-radius: 0.25rem;
  --bs-nav-tabs-link-hover-border-color: #f0f0f0 #f0f0f0 #e6e6e6;
  --bs-nav-tabs-link-active-color: #595959;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #e6e6e6 #e6e6e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width)); }

.nav-pills {
  --bs-nav-pills-border-radius: 0.25rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #B96F4A; }
  .nav-pills .nav-link {
    background: none;
    border: 0; }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 1rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.39375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.125rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 1rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.125rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.25rem;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-gray-300);
  --bs-card-border-radius: 0.4rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.4rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: var(--bs-white);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0; }
    .card > .list-group:last-child {
      border-bottom-width: 0; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; } }

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.25rem;
  --bs-accordion-inner-border-radius: calc(0.25rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a76443'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #dcb7a5;
  --bs-accordion-btn-focus-box-shadow: unset;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #a76443;
  --bs-accordion-active-bg: #f8f1ed; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #737373;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #737373;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #e6e6e6;
  --bs-pagination-border-radius: 0.25rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #f0f0f0;
  --bs-pagination-hover-border-color: #e6e6e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #f0f0f0;
  --bs-pagination-focus-box-shadow: unset;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #B96F4A;
  --bs-pagination-active-border-color: #B96F4A;
  --bs-pagination-disabled-color: #737373;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #e6e6e6;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.125rem;
  --bs-pagination-border-radius: 0.75rem; }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.88rem;
  --bs-pagination-border-radius: 0.2rem; }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.25rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: #6f432c;
  --bs-alert-bg: #f1e2db;
  --bs-alert-border-color: #ead4c9; }
  .alert-primary .alert-link {
    color: #593623; }

.alert-secondary {
  --bs-alert-color: #99433a;
  --bs-alert-bg: #ffe2df;
  --bs-alert-border-color: #ffd4d0; }
  .alert-secondary .alert-link {
    color: #7a362e; }

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc; }
  .alert-success .alert-link {
    color: #0c4128; }

.alert-info {
  --bs-alert-color: #087990;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb; }
  .alert-info .alert-link {
    color: #066173; }

.alert-warning {
  --bs-alert-color: #997404;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5; }
  .alert-warning .alert-link {
    color: #7a5d03; }

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7; }
  .alert-danger .alert-link {
    color: #6a1a21; }

.alert-light {
  --bs-alert-color: #959596;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #777778; }

.alert-white {
  --bs-alert-color: #999999;
  --bs-alert-bg: white;
  --bs-alert-border-color: white; }
  .alert-white .alert-link {
    color: #7a7a7a; }

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf; }
  .alert-dark .alert-link {
    color: #101214; }

.alert-gray-100 {
  --bs-alert-color: #969696;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fefefe; }
  .alert-gray-100 .alert-link {
    color: #787878; }

.alert-gray-200 {
  --bs-alert-color: #909090;
  --bs-alert-bg: #fcfcfc;
  --bs-alert-border-color: #fbfbfb; }
  .alert-gray-200 .alert-link {
    color: #737373; }

.alert-gray-300 {
  --bs-alert-color: #8a8a8a;
  --bs-alert-bg: #fafafa;
  --bs-alert-border-color: #f8f8f8; }
  .alert-gray-300 .alert-link {
    color: #6e6e6e; }

.alert-gray-400 {
  --bs-alert-color: #838383;
  --bs-alert-bg: #f8f8f8;
  --bs-alert-border-color: #f4f4f4; }
  .alert-gray-400 .alert-link {
    color: dimgray; }

.alert-gray-500 {
  --bs-alert-color: #7d7d7d;
  --bs-alert-bg: #f6f6f6;
  --bs-alert-border-color: #f1f1f1; }
  .alert-gray-500 .alert-link {
    color: #646464; }

.alert-gray-600 {
  --bs-alert-color: #454545;
  --bs-alert-bg: #e3e3e3;
  --bs-alert-border-color: #d5d5d5; }
  .alert-gray-600 .alert-link {
    color: #373737; }

.alert-gray-700 {
  --bs-alert-color: #353535;
  --bs-alert-bg: #dedede;
  --bs-alert-border-color: #cdcdcd; }
  .alert-gray-700 .alert-link {
    color: #2a2a2a; }

.alert-gray-800 {
  --bs-alert-color: #262626;
  --bs-alert-bg: #d9d9d9;
  --bs-alert-border-color: #c6c6c6; }
  .alert-gray-800 .alert-link {
    color: #1e1e1e; }

.alert-gray-900 {
  --bs-alert-color: #171717;
  --bs-alert-bg: #d4d4d4;
  --bs-alert-border-color: #bebebe; }
  .alert-gray-900 .alert-link {
    color: #121212; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #f0f0f0;
  --bs-progress-border-radius: 0.25rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #B96F4A;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: #262626;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.25rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #595959;
  --bs-list-group-action-hover-color: #595959;
  --bs-list-group-action-hover-bg: #fafafa;
  --bs-list-group-action-active-color: #6C757D;
  --bs-list-group-action-active-bg: #f0f0f0;
  --bs-list-group-disabled-color: #737373;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #B96F4A;
  --bs-list-group-active-border-color: #B96F4A;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width); }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0; }

.list-group-item-primary {
  color: #6f432c;
  background-color: #f1e2db; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #6f432c;
    background-color: #d9cbc5; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #6f432c;
    border-color: #6f432c; }

.list-group-item-secondary {
  color: #99433a;
  background-color: #ffe2df; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #99433a;
    background-color: #e6cbc9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #99433a;
    border-color: #99433a; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f5132;
    background-color: #bcd0c7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132; }

.list-group-item-info {
  color: #087990;
  background-color: #cff4fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #087990;
    background-color: #badce3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #087990;
    border-color: #087990; }

.list-group-item-warning {
  color: #997404;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #997404;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #997404;
    border-color: #997404; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #959596;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #959596;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #959596;
    border-color: #959596; }

.list-group-item-white {
  color: #999999;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #999999;
    background-color: #e6e6e6; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #999999;
    border-color: #999999; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619; }

.list-group-item-gray-100 {
  color: #969696;
  background-color: #fefefe; }
  .list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
    color: #969696;
    background-color: #e5e5e5; }
  .list-group-item-gray-100.list-group-item-action.active {
    color: #fff;
    background-color: #969696;
    border-color: #969696; }

.list-group-item-gray-200 {
  color: #909090;
  background-color: #fcfcfc; }
  .list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
    color: #909090;
    background-color: #e3e3e3; }
  .list-group-item-gray-200.list-group-item-action.active {
    color: #fff;
    background-color: #909090;
    border-color: #909090; }

.list-group-item-gray-300 {
  color: #8a8a8a;
  background-color: #fafafa; }
  .list-group-item-gray-300.list-group-item-action:hover, .list-group-item-gray-300.list-group-item-action:focus {
    color: #8a8a8a;
    background-color: #e1e1e1; }
  .list-group-item-gray-300.list-group-item-action.active {
    color: #fff;
    background-color: #8a8a8a;
    border-color: #8a8a8a; }

.list-group-item-gray-400 {
  color: #838383;
  background-color: #f8f8f8; }
  .list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
    color: #838383;
    background-color: #dfdfdf; }
  .list-group-item-gray-400.list-group-item-action.active {
    color: #fff;
    background-color: #838383;
    border-color: #838383; }

.list-group-item-gray-500 {
  color: #7d7d7d;
  background-color: #f6f6f6; }
  .list-group-item-gray-500.list-group-item-action:hover, .list-group-item-gray-500.list-group-item-action:focus {
    color: #7d7d7d;
    background-color: #dddddd; }
  .list-group-item-gray-500.list-group-item-action.active {
    color: #fff;
    background-color: #7d7d7d;
    border-color: #7d7d7d; }

.list-group-item-gray-600 {
  color: #454545;
  background-color: #e3e3e3; }
  .list-group-item-gray-600.list-group-item-action:hover, .list-group-item-gray-600.list-group-item-action:focus {
    color: #454545;
    background-color: #cccccc; }
  .list-group-item-gray-600.list-group-item-action.active {
    color: #fff;
    background-color: #454545;
    border-color: #454545; }

.list-group-item-gray-700 {
  color: #353535;
  background-color: #dedede; }
  .list-group-item-gray-700.list-group-item-action:hover, .list-group-item-gray-700.list-group-item-action:focus {
    color: #353535;
    background-color: #c8c8c8; }
  .list-group-item-gray-700.list-group-item-action.active {
    color: #fff;
    background-color: #353535;
    border-color: #353535; }

.list-group-item-gray-800 {
  color: #262626;
  background-color: #d9d9d9; }
  .list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
    color: #262626;
    background-color: #c3c3c3; }
  .list-group-item-gray-800.list-group-item-action.active {
    color: #fff;
    background-color: #262626;
    border-color: #262626; }

.list-group-item-gray-900 {
  color: #171717;
  background-color: #d4d4d4; }
  .list-group-item-gray-900.list-group-item-action:hover, .list-group-item-gray-900.list-group-item-action:focus {
    color: #171717;
    background-color: #bfbfbf; }
  .list-group-item-gray-900.list-group-item-action.active {
    color: #fff;
    background-color: #171717;
    border-color: #171717; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: unset;
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.25rem;
  --bs-toast-box-shadow: 0px 2px 4px -1px rgba(var(--bs-dark-rgb), 0.1), 0px 2px 4px -2px rgba(var(--bs-dark-rgb), 0.1);
  --bs-toast-header-color: #737373;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.75rem;
  --bs-modal-box-shadow: 0px 1px 3px rgba(var(--bs-dark-rgb), 0.04), 0px 1px 2px -1px rgba(var(--bs-dark-rgb), 0.05);
  --bs-modal-inner-border-radius: calc(0.75rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.7;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0px 2px 4px -1px rgba(var(--bs-dark-rgb), 0.1), 0px 2px 4px -2px rgba(var(--bs-dark-rgb), 0.1); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.88rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.25rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.88rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.75rem;
  --bs-popover-inner-border-radius: calc(0.75rem - 1px);
  --bs-popover-box-shadow: 0px 2px 4px -1px rgba(var(--bs-dark-rgb), 0.1), 0px 2px 4px -2px rgba(var(--bs-dark-rgb), 0.1);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #152C5B;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #6C757D;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0px 1px 3px rgba(var(--bs-dark-rgb), 0.04), 0px 1px 2px -1px rgba(var(--bs-dark-rgb), 0.05); }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.7; }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(185, 111, 74, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(255, 111, 97, var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important; }

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-100 {
  color: #000 !important;
  background-color: RGBA(250, 250, 250, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-200 {
  color: #000 !important;
  background-color: RGBA(240, 240, 240, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-300 {
  color: #000 !important;
  background-color: RGBA(230, 230, 230, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-400 {
  color: #000 !important;
  background-color: RGBA(219, 219, 219, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-500 {
  color: #000 !important;
  background-color: RGBA(209, 209, 209, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-600 {
  color: #fff !important;
  background-color: RGBA(115, 115, 115, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-700 {
  color: #fff !important;
  background-color: RGBA(89, 89, 89, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-800 {
  color: #fff !important;
  background-color: RGBA(64, 64, 64, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-900 {
  color: #fff !important;
  background-color: RGBA(38, 38, 38, var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: #B96F4A !important; }
  .link-primary:hover, .link-primary:focus {
    color: #94593b !important; }

.link-secondary {
  color: #FF6F61 !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #cc594e !important; }

.link-success {
  color: #198754 !important; }
  .link-success:hover, .link-success:focus {
    color: #146c43 !important; }

.link-info {
  color: #0dcaf0 !important; }
  .link-info:hover, .link-info:focus {
    color: #0aa2c0 !important; }

.link-warning {
  color: #ffc107 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39 !important; }

.link-danger {
  color: #dc3545 !important; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37 !important; }

.link-light {
  color: #F8F9FA !important; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb !important; }

.link-white {
  color: #fff !important; }
  .link-white:hover, .link-white:focus {
    color: white !important; }

.link-dark {
  color: #212529 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #1a1e21 !important; }

.link-gray-100 {
  color: #fafafa !important; }
  .link-gray-100:hover, .link-gray-100:focus {
    color: #fbfbfb !important; }

.link-gray-200 {
  color: #f0f0f0 !important; }
  .link-gray-200:hover, .link-gray-200:focus {
    color: #f3f3f3 !important; }

.link-gray-300 {
  color: #e6e6e6 !important; }
  .link-gray-300:hover, .link-gray-300:focus {
    color: #ebebeb !important; }

.link-gray-400 {
  color: #dbdbdb !important; }
  .link-gray-400:hover, .link-gray-400:focus {
    color: #e2e2e2 !important; }

.link-gray-500 {
  color: #d1d1d1 !important; }
  .link-gray-500:hover, .link-gray-500:focus {
    color: #dadada !important; }

.link-gray-600 {
  color: #737373 !important; }
  .link-gray-600:hover, .link-gray-600:focus {
    color: #5c5c5c !important; }

.link-gray-700 {
  color: #595959 !important; }
  .link-gray-700:hover, .link-gray-700:focus {
    color: #474747 !important; }

.link-gray-800 {
  color: #404040 !important; }
  .link-gray-800:hover, .link-gray-800:focus {
    color: #333333 !important; }

.link-gray-900 {
  color: #262626 !important; }
  .link-gray-900:hover, .link-gray-900:focus {
    color: #1e1e1e !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-1 {
  opacity: 0.1 !important; }

.opacity-2 {
  opacity: 0.2 !important; }

.opacity-3 {
  opacity: 0.3 !important; }

.opacity-4 {
  opacity: 0.4 !important; }

.opacity-5 {
  opacity: 0.5 !important; }

.opacity-6 {
  opacity: 0.6 !important; }

.opacity-7 {
  opacity: 0.7 !important; }

.opacity-8 {
  opacity: 0.8 !important; }

.opacity-9 {
  opacity: 0.9 !important; }

.opacity-10 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0px 2px 4px -1px rgba(var(--bs-dark-rgb), 0.1), 0px 2px 4px -2px rgba(var(--bs-dark-rgb), 0.1) !important; }

.shadow-sm {
  box-shadow: 0px 1px 3px rgba(var(--bs-dark-rgb), 0.04), 0px 1px 2px -1px rgba(var(--bs-dark-rgb), 0.05) !important; }

.shadow-lg {
  box-shadow: 0px 10px 15px -3px rgba(var(--bs-dark-rgb), 0.05), 0px 4px 6px -4px rgba(var(--bs-dark-rgb), 0.05) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-gray-100 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-100-rgb), var(--bs-border-opacity)) !important; }

.border-gray-200 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-200-rgb), var(--bs-border-opacity)) !important; }

.border-gray-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-300-rgb), var(--bs-border-opacity)) !important; }

.border-gray-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-400-rgb), var(--bs-border-opacity)) !important; }

.border-gray-500 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-500-rgb), var(--bs-border-opacity)) !important; }

.border-gray-600 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-600-rgb), var(--bs-border-opacity)) !important; }

.border-gray-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-700-rgb), var(--bs-border-opacity)) !important; }

.border-gray-800 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-800-rgb), var(--bs-border-opacity)) !important; }

.border-gray-900 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-900-rgb), var(--bs-border-opacity)) !important; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-25 {
  width: 25% !important; }

.w-30 {
  width: 30% !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.w-45 {
  width: 45% !important; }

.w-50 {
  width: 50% !important; }

.w-55 {
  width: 55% !important; }

.w-60 {
  width: 60% !important; }

.w-65 {
  width: 65% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-85 {
  width: 85% !important; }

.w-90 {
  width: 90% !important; }

.w-95 {
  width: 95% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-50 {
  min-height: 50vh !important; }

.min-vh-65 {
  min-height: 65vh !important; }

.min-vh-75 {
  min-height: 75vh !important; }

.min-vh-85 {
  min-height: 85vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.m-6 {
  margin: 2.5rem !important; }

.m-7 {
  margin: 3rem !important; }

.m-8 {
  margin: 3.5rem !important; }

.m-9 {
  margin: 4rem !important; }

.m-10 {
  margin: 5rem !important; }

.m-11 {
  margin: 6rem !important; }

.m-12 {
  margin: 9rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important; }

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important; }

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important; }

.mx-10 {
  margin-right: 5rem !important;
  margin-left: 5rem !important; }

.mx-11 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.mx-12 {
  margin-right: 9rem !important;
  margin-left: 9rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important; }

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.my-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.my-11 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-12 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 2rem !important; }

.mt-6 {
  margin-top: 2.5rem !important; }

.mt-7 {
  margin-top: 3rem !important; }

.mt-8 {
  margin-top: 3.5rem !important; }

.mt-9 {
  margin-top: 4rem !important; }

.mt-10 {
  margin-top: 5rem !important; }

.mt-11 {
  margin-top: 6rem !important; }

.mt-12 {
  margin-top: 9rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 2rem !important; }

.me-6 {
  margin-right: 2.5rem !important; }

.me-7 {
  margin-right: 3rem !important; }

.me-8 {
  margin-right: 3.5rem !important; }

.me-9 {
  margin-right: 4rem !important; }

.me-10 {
  margin-right: 5rem !important; }

.me-11 {
  margin-right: 6rem !important; }

.me-12 {
  margin-right: 9rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 2rem !important; }

.mb-6 {
  margin-bottom: 2.5rem !important; }

.mb-7 {
  margin-bottom: 3rem !important; }

.mb-8 {
  margin-bottom: 3.5rem !important; }

.mb-9 {
  margin-bottom: 4rem !important; }

.mb-10 {
  margin-bottom: 5rem !important; }

.mb-11 {
  margin-bottom: 6rem !important; }

.mb-12 {
  margin-bottom: 9rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 2rem !important; }

.ms-6 {
  margin-left: 2.5rem !important; }

.ms-7 {
  margin-left: 3rem !important; }

.ms-8 {
  margin-left: 3.5rem !important; }

.ms-9 {
  margin-left: 4rem !important; }

.ms-10 {
  margin-left: 5rem !important; }

.ms-11 {
  margin-left: 6rem !important; }

.ms-12 {
  margin-left: 9rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -2rem !important; }

.m-n6 {
  margin: -2.5rem !important; }

.m-n7 {
  margin: -3rem !important; }

.m-n8 {
  margin: -3.5rem !important; }

.m-n9 {
  margin: -4rem !important; }

.m-n10 {
  margin: -5rem !important; }

.m-n11 {
  margin: -6rem !important; }

.m-n12 {
  margin: -9rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important; }

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important; }

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important; }

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important; }

.mx-n10 {
  margin-right: -5rem !important;
  margin-left: -5rem !important; }

.mx-n11 {
  margin-right: -6rem !important;
  margin-left: -6rem !important; }

.mx-n12 {
  margin-right: -9rem !important;
  margin-left: -9rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important; }

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important; }

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important; }

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important; }

.my-n10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important; }

.my-n11 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important; }

.my-n12 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -2rem !important; }

.mt-n6 {
  margin-top: -2.5rem !important; }

.mt-n7 {
  margin-top: -3rem !important; }

.mt-n8 {
  margin-top: -3.5rem !important; }

.mt-n9 {
  margin-top: -4rem !important; }

.mt-n10 {
  margin-top: -5rem !important; }

.mt-n11 {
  margin-top: -6rem !important; }

.mt-n12 {
  margin-top: -9rem !important; }

.me-n1 {
  margin-right: -0.25rem !important; }

.me-n2 {
  margin-right: -0.5rem !important; }

.me-n3 {
  margin-right: -1rem !important; }

.me-n4 {
  margin-right: -1.5rem !important; }

.me-n5 {
  margin-right: -2rem !important; }

.me-n6 {
  margin-right: -2.5rem !important; }

.me-n7 {
  margin-right: -3rem !important; }

.me-n8 {
  margin-right: -3.5rem !important; }

.me-n9 {
  margin-right: -4rem !important; }

.me-n10 {
  margin-right: -5rem !important; }

.me-n11 {
  margin-right: -6rem !important; }

.me-n12 {
  margin-right: -9rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -2rem !important; }

.mb-n6 {
  margin-bottom: -2.5rem !important; }

.mb-n7 {
  margin-bottom: -3rem !important; }

.mb-n8 {
  margin-bottom: -3.5rem !important; }

.mb-n9 {
  margin-bottom: -4rem !important; }

.mb-n10 {
  margin-bottom: -5rem !important; }

.mb-n11 {
  margin-bottom: -6rem !important; }

.mb-n12 {
  margin-bottom: -9rem !important; }

.ms-n1 {
  margin-left: -0.25rem !important; }

.ms-n2 {
  margin-left: -0.5rem !important; }

.ms-n3 {
  margin-left: -1rem !important; }

.ms-n4 {
  margin-left: -1.5rem !important; }

.ms-n5 {
  margin-left: -2rem !important; }

.ms-n6 {
  margin-left: -2.5rem !important; }

.ms-n7 {
  margin-left: -3rem !important; }

.ms-n8 {
  margin-left: -3.5rem !important; }

.ms-n9 {
  margin-left: -4rem !important; }

.ms-n10 {
  margin-left: -5rem !important; }

.ms-n11 {
  margin-left: -6rem !important; }

.ms-n12 {
  margin-left: -9rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.p-6 {
  padding: 2.5rem !important; }

.p-7 {
  padding: 3rem !important; }

.p-8 {
  padding: 3.5rem !important; }

.p-9 {
  padding: 4rem !important; }

.p-10 {
  padding: 5rem !important; }

.p-11 {
  padding: 6rem !important; }

.p-12 {
  padding: 9rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important; }

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important; }

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.px-10 {
  padding-right: 5rem !important;
  padding-left: 5rem !important; }

.px-11 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.px-12 {
  padding-right: 9rem !important;
  padding-left: 9rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important; }

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.py-11 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.py-12 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 2rem !important; }

.pt-6 {
  padding-top: 2.5rem !important; }

.pt-7 {
  padding-top: 3rem !important; }

.pt-8 {
  padding-top: 3.5rem !important; }

.pt-9 {
  padding-top: 4rem !important; }

.pt-10 {
  padding-top: 5rem !important; }

.pt-11 {
  padding-top: 6rem !important; }

.pt-12 {
  padding-top: 9rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 2rem !important; }

.pe-6 {
  padding-right: 2.5rem !important; }

.pe-7 {
  padding-right: 3rem !important; }

.pe-8 {
  padding-right: 3.5rem !important; }

.pe-9 {
  padding-right: 4rem !important; }

.pe-10 {
  padding-right: 5rem !important; }

.pe-11 {
  padding-right: 6rem !important; }

.pe-12 {
  padding-right: 9rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 2rem !important; }

.pb-6 {
  padding-bottom: 2.5rem !important; }

.pb-7 {
  padding-bottom: 3rem !important; }

.pb-8 {
  padding-bottom: 3.5rem !important; }

.pb-9 {
  padding-bottom: 4rem !important; }

.pb-10 {
  padding-bottom: 5rem !important; }

.pb-11 {
  padding-bottom: 6rem !important; }

.pb-12 {
  padding-bottom: 9rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 2rem !important; }

.ps-6 {
  padding-left: 2.5rem !important; }

.ps-7 {
  padding-left: 3rem !important; }

.ps-8 {
  padding-left: 3.5rem !important; }

.ps-9 {
  padding-left: 4rem !important; }

.ps-10 {
  padding-left: 5rem !important; }

.ps-11 {
  padding-left: 6rem !important; }

.ps-12 {
  padding-left: 9rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 2rem !important; }

.gap-6 {
  gap: 2.5rem !important; }

.gap-7 {
  gap: 3rem !important; }

.gap-8 {
  gap: 3.5rem !important; }

.gap-9 {
  gap: 4rem !important; }

.gap-10 {
  gap: 5rem !important; }

.gap-11 {
  gap: 6rem !important; }

.gap-12 {
  gap: 9rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fs-xl {
  font-size: 1.25rem !important; }

.fs-lg {
  font-size: 1.125rem !important; }

.fs-base {
  font-size: 1rem !important; }

.fs-sm {
  font-size: 0.88rem !important; }

.fs-xs {
  font-size: 0.8rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.4 !important; }

.lh-base {
  line-height: 1.7 !important; }

.lh-lg {
  line-height: 1.8 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-100-rgb), var(--bs-text-opacity)) !important; }

.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-200-rgb), var(--bs-text-opacity)) !important; }

.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-300-rgb), var(--bs-text-opacity)) !important; }

.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-400-rgb), var(--bs-text-opacity)) !important; }

.text-gray-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-500-rgb), var(--bs-text-opacity)) !important; }

.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-600-rgb), var(--bs-text-opacity)) !important; }

.text-gray-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-700-rgb), var(--bs-text-opacity)) !important; }

.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-800-rgb), var(--bs-text-opacity)) !important; }

.text-gray-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-900-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #737373 !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-300-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-400-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-500-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-600-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-700-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-900-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-20 {
  --bs-bg-opacity: 0.2; }

.bg-opacity-30 {
  --bs-bg-opacity: 0.3; }

.bg-opacity-40 {
  --bs-bg-opacity: 0.4; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-60 {
  --bs-bg-opacity: 0.6; }

.bg-opacity-70 {
  --bs-bg-opacity: 0.7; }

.bg-opacity-80 {
  --bs-bg-opacity: 0.8; }

.bg-opacity-90 {
  --bs-bg-opacity: 0.9; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.fw-100 {
  font-weight: 100 !important; }

.fw-200 {
  font-weight: 200 !important; }

.fw-300 {
  font-weight: 300 !important; }

.fw-400 {
  font-weight: 400 !important; }

.fw-500 {
  font-weight: 500 !important; }

.fw-600 {
  font-weight: 600 !important; }

.fw-700 {
  font-weight: 700 !important; }

.fw-800 {
  font-weight: 800 !important; }

.fw-900 {
  font-weight: 900 !important; }

.letter-spacing-1 {
  letter-spacing: 1px !important; }

.letter-spacing-2 {
  letter-spacing: 2px !important; }

.letter-spacing-3 {
  letter-spacing: 3px !important; }

.letter-spacing-4 {
  letter-spacing: 4px !important; }

.letter-spacing-5 {
  letter-spacing: 5px !important; }

.letter-spacing-6 {
  letter-spacing: 6px !important; }

.letter-spacing-7 {
  letter-spacing: 7px !important; }

.letter-spacing-8 {
  letter-spacing: 8px !important; }

.letter-spacing-9 {
  letter-spacing: 9px !important; }

.letter-spacing-10 {
  letter-spacing: 10px !important; }

.img-auto {
  max-width: inherit !important; }

.img-120 {
  max-width: 120% !important; }

.img-140 {
  max-width: 140% !important; }

.z-index--1 {
  z-index: -1 !important; }

.z-index--2 {
  z-index: -2 !important; }

.z-index--3 {
  z-index: -3 !important; }

.z-index--4 {
  z-index: -4 !important; }

.z-index--5 {
  z-index: -5 !important; }

.z-index--6 {
  z-index: -6 !important; }

.z-index--7 {
  z-index: -7 !important; }

.z-index--8 {
  z-index: -8 !important; }

.z-index--9 {
  z-index: -9 !important; }

.z-index--10 {
  z-index: -10 !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

.z-index-6 {
  z-index: 6 !important; }

.z-index-7 {
  z-index: 7 !important; }

.z-index-8 {
  z-index: 8 !important; }

.z-index-9 {
  z-index: 9 !important; }

.z-index-10 {
  z-index: 10 !important; }

.h-px-1 {
  height: 1px !important; }

.h-px-5 {
  height: 5px !important; }

.h-px-10 {
  height: 10px !important; }

.h-px-100 {
  height: 100px !important; }

.h-px-250 {
  height: 250px !important; }

.h-px-300 {
  height: 300px !important; }

.h-px-350 {
  height: 350px !important; }

.min-h-px-250 {
  min-height: 250px !important; }

.min-h-px-300 {
  min-height: 300px !important; }

.min-h-px-350 {
  min-height: 350px !important; }

.min-h-px-450 {
  min-height: 450px !important; }

.min-h-px-550 {
  min-height: 550px !important; }

.w-px-50 {
  width: 50px !important; }

.w-px-100 {
  width: 100px !important; }

.w-px-150 {
  width: 150px !important; }

.w-px-200 {
  width: 200px !important; }

.w-px-250 {
  width: 250px !important; }

.w-px-300 {
  width: 300px !important; }

.w-px-350 {
  width: 350px !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .w-sm-5 {
    width: 5% !important; }
  .w-sm-10 {
    width: 10% !important; }
  .w-sm-15 {
    width: 15% !important; }
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-30 {
    width: 30% !important; }
  .w-sm-35 {
    width: 35% !important; }
  .w-sm-40 {
    width: 40% !important; }
  .w-sm-45 {
    width: 45% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-55 {
    width: 55% !important; }
  .w-sm-60 {
    width: 60% !important; }
  .w-sm-65 {
    width: 65% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-80 {
    width: 80% !important; }
  .w-sm-85 {
    width: 85% !important; }
  .w-sm-90 {
    width: 90% !important; }
  .w-sm-95 {
    width: 95% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .min-vh-sm-50 {
    min-height: 50vh !important; }
  .min-vh-sm-65 {
    min-height: 65vh !important; }
  .min-vh-sm-75 {
    min-height: 75vh !important; }
  .min-vh-sm-85 {
    min-height: 85vh !important; }
  .min-vh-sm-100 {
    min-height: 100vh !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .m-sm-6 {
    margin: 2.5rem !important; }
  .m-sm-7 {
    margin: 3rem !important; }
  .m-sm-8 {
    margin: 3.5rem !important; }
  .m-sm-9 {
    margin: 4rem !important; }
  .m-sm-10 {
    margin: 5rem !important; }
  .m-sm-11 {
    margin: 6rem !important; }
  .m-sm-12 {
    margin: 9rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-sm-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-sm-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-sm-12 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-sm-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-sm-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-sm-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 2rem !important; }
  .mt-sm-6 {
    margin-top: 2.5rem !important; }
  .mt-sm-7 {
    margin-top: 3rem !important; }
  .mt-sm-8 {
    margin-top: 3.5rem !important; }
  .mt-sm-9 {
    margin-top: 4rem !important; }
  .mt-sm-10 {
    margin-top: 5rem !important; }
  .mt-sm-11 {
    margin-top: 6rem !important; }
  .mt-sm-12 {
    margin-top: 9rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 2rem !important; }
  .me-sm-6 {
    margin-right: 2.5rem !important; }
  .me-sm-7 {
    margin-right: 3rem !important; }
  .me-sm-8 {
    margin-right: 3.5rem !important; }
  .me-sm-9 {
    margin-right: 4rem !important; }
  .me-sm-10 {
    margin-right: 5rem !important; }
  .me-sm-11 {
    margin-right: 6rem !important; }
  .me-sm-12 {
    margin-right: 9rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 2rem !important; }
  .mb-sm-6 {
    margin-bottom: 2.5rem !important; }
  .mb-sm-7 {
    margin-bottom: 3rem !important; }
  .mb-sm-8 {
    margin-bottom: 3.5rem !important; }
  .mb-sm-9 {
    margin-bottom: 4rem !important; }
  .mb-sm-10 {
    margin-bottom: 5rem !important; }
  .mb-sm-11 {
    margin-bottom: 6rem !important; }
  .mb-sm-12 {
    margin-bottom: 9rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 2rem !important; }
  .ms-sm-6 {
    margin-left: 2.5rem !important; }
  .ms-sm-7 {
    margin-left: 3rem !important; }
  .ms-sm-8 {
    margin-left: 3.5rem !important; }
  .ms-sm-9 {
    margin-left: 4rem !important; }
  .ms-sm-10 {
    margin-left: 5rem !important; }
  .ms-sm-11 {
    margin-left: 6rem !important; }
  .ms-sm-12 {
    margin-left: 9rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -2rem !important; }
  .m-sm-n6 {
    margin: -2.5rem !important; }
  .m-sm-n7 {
    margin: -3rem !important; }
  .m-sm-n8 {
    margin: -3.5rem !important; }
  .m-sm-n9 {
    margin: -4rem !important; }
  .m-sm-n10 {
    margin: -5rem !important; }
  .m-sm-n11 {
    margin: -6rem !important; }
  .m-sm-n12 {
    margin: -9rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-sm-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-sm-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-sm-n12 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-sm-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-sm-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-sm-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -2rem !important; }
  .mt-sm-n6 {
    margin-top: -2.5rem !important; }
  .mt-sm-n7 {
    margin-top: -3rem !important; }
  .mt-sm-n8 {
    margin-top: -3.5rem !important; }
  .mt-sm-n9 {
    margin-top: -4rem !important; }
  .mt-sm-n10 {
    margin-top: -5rem !important; }
  .mt-sm-n11 {
    margin-top: -6rem !important; }
  .mt-sm-n12 {
    margin-top: -9rem !important; }
  .me-sm-n1 {
    margin-right: -0.25rem !important; }
  .me-sm-n2 {
    margin-right: -0.5rem !important; }
  .me-sm-n3 {
    margin-right: -1rem !important; }
  .me-sm-n4 {
    margin-right: -1.5rem !important; }
  .me-sm-n5 {
    margin-right: -2rem !important; }
  .me-sm-n6 {
    margin-right: -2.5rem !important; }
  .me-sm-n7 {
    margin-right: -3rem !important; }
  .me-sm-n8 {
    margin-right: -3.5rem !important; }
  .me-sm-n9 {
    margin-right: -4rem !important; }
  .me-sm-n10 {
    margin-right: -5rem !important; }
  .me-sm-n11 {
    margin-right: -6rem !important; }
  .me-sm-n12 {
    margin-right: -9rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -2rem !important; }
  .mb-sm-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-sm-n7 {
    margin-bottom: -3rem !important; }
  .mb-sm-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-sm-n9 {
    margin-bottom: -4rem !important; }
  .mb-sm-n10 {
    margin-bottom: -5rem !important; }
  .mb-sm-n11 {
    margin-bottom: -6rem !important; }
  .mb-sm-n12 {
    margin-bottom: -9rem !important; }
  .ms-sm-n1 {
    margin-left: -0.25rem !important; }
  .ms-sm-n2 {
    margin-left: -0.5rem !important; }
  .ms-sm-n3 {
    margin-left: -1rem !important; }
  .ms-sm-n4 {
    margin-left: -1.5rem !important; }
  .ms-sm-n5 {
    margin-left: -2rem !important; }
  .ms-sm-n6 {
    margin-left: -2.5rem !important; }
  .ms-sm-n7 {
    margin-left: -3rem !important; }
  .ms-sm-n8 {
    margin-left: -3.5rem !important; }
  .ms-sm-n9 {
    margin-left: -4rem !important; }
  .ms-sm-n10 {
    margin-left: -5rem !important; }
  .ms-sm-n11 {
    margin-left: -6rem !important; }
  .ms-sm-n12 {
    margin-left: -9rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .p-sm-6 {
    padding: 2.5rem !important; }
  .p-sm-7 {
    padding: 3rem !important; }
  .p-sm-8 {
    padding: 3.5rem !important; }
  .p-sm-9 {
    padding: 4rem !important; }
  .p-sm-10 {
    padding: 5rem !important; }
  .p-sm-11 {
    padding: 6rem !important; }
  .p-sm-12 {
    padding: 9rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-sm-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-sm-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-sm-12 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-sm-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-sm-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-sm-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 2rem !important; }
  .pt-sm-6 {
    padding-top: 2.5rem !important; }
  .pt-sm-7 {
    padding-top: 3rem !important; }
  .pt-sm-8 {
    padding-top: 3.5rem !important; }
  .pt-sm-9 {
    padding-top: 4rem !important; }
  .pt-sm-10 {
    padding-top: 5rem !important; }
  .pt-sm-11 {
    padding-top: 6rem !important; }
  .pt-sm-12 {
    padding-top: 9rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 2rem !important; }
  .pe-sm-6 {
    padding-right: 2.5rem !important; }
  .pe-sm-7 {
    padding-right: 3rem !important; }
  .pe-sm-8 {
    padding-right: 3.5rem !important; }
  .pe-sm-9 {
    padding-right: 4rem !important; }
  .pe-sm-10 {
    padding-right: 5rem !important; }
  .pe-sm-11 {
    padding-right: 6rem !important; }
  .pe-sm-12 {
    padding-right: 9rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 2rem !important; }
  .pb-sm-6 {
    padding-bottom: 2.5rem !important; }
  .pb-sm-7 {
    padding-bottom: 3rem !important; }
  .pb-sm-8 {
    padding-bottom: 3.5rem !important; }
  .pb-sm-9 {
    padding-bottom: 4rem !important; }
  .pb-sm-10 {
    padding-bottom: 5rem !important; }
  .pb-sm-11 {
    padding-bottom: 6rem !important; }
  .pb-sm-12 {
    padding-bottom: 9rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 2rem !important; }
  .ps-sm-6 {
    padding-left: 2.5rem !important; }
  .ps-sm-7 {
    padding-left: 3rem !important; }
  .ps-sm-8 {
    padding-left: 3.5rem !important; }
  .ps-sm-9 {
    padding-left: 4rem !important; }
  .ps-sm-10 {
    padding-left: 5rem !important; }
  .ps-sm-11 {
    padding-left: 6rem !important; }
  .ps-sm-12 {
    padding-left: 9rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 2rem !important; }
  .gap-sm-6 {
    gap: 2.5rem !important; }
  .gap-sm-7 {
    gap: 3rem !important; }
  .gap-sm-8 {
    gap: 3.5rem !important; }
  .gap-sm-9 {
    gap: 4rem !important; }
  .gap-sm-10 {
    gap: 5rem !important; }
  .gap-sm-11 {
    gap: 6rem !important; }
  .gap-sm-12 {
    gap: 9rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .letter-spacing-sm-1 {
    letter-spacing: 1px !important; }
  .letter-spacing-sm-2 {
    letter-spacing: 2px !important; }
  .letter-spacing-sm-3 {
    letter-spacing: 3px !important; }
  .letter-spacing-sm-4 {
    letter-spacing: 4px !important; }
  .letter-spacing-sm-5 {
    letter-spacing: 5px !important; }
  .letter-spacing-sm-6 {
    letter-spacing: 6px !important; }
  .letter-spacing-sm-7 {
    letter-spacing: 7px !important; }
  .letter-spacing-sm-8 {
    letter-spacing: 8px !important; }
  .letter-spacing-sm-9 {
    letter-spacing: 9px !important; }
  .letter-spacing-sm-10 {
    letter-spacing: 10px !important; }
  .img-sm-auto {
    max-width: inherit !important; }
  .img-sm-120 {
    max-width: 120% !important; }
  .img-sm-140 {
    max-width: 140% !important; }
  .h-px-sm-1 {
    height: 1px !important; }
  .h-px-sm-5 {
    height: 5px !important; }
  .h-px-sm-10 {
    height: 10px !important; }
  .h-px-sm-100 {
    height: 100px !important; }
  .h-px-sm-250 {
    height: 250px !important; }
  .h-px-sm-300 {
    height: 300px !important; }
  .h-px-sm-350 {
    height: 350px !important; }
  .min-h-px-sm-250 {
    min-height: 250px !important; }
  .min-h-px-sm-300 {
    min-height: 300px !important; }
  .min-h-px-sm-350 {
    min-height: 350px !important; }
  .min-h-px-sm-450 {
    min-height: 450px !important; }
  .min-h-px-sm-550 {
    min-height: 550px !important; }
  .w-px-sm-50 {
    width: 50px !important; }
  .w-px-sm-100 {
    width: 100px !important; }
  .w-px-sm-150 {
    width: 150px !important; }
  .w-px-sm-200 {
    width: 200px !important; }
  .w-px-sm-250 {
    width: 250px !important; }
  .w-px-sm-300 {
    width: 300px !important; }
  .w-px-sm-350 {
    width: 350px !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .w-md-5 {
    width: 5% !important; }
  .w-md-10 {
    width: 10% !important; }
  .w-md-15 {
    width: 15% !important; }
  .w-md-25 {
    width: 25% !important; }
  .w-md-30 {
    width: 30% !important; }
  .w-md-35 {
    width: 35% !important; }
  .w-md-40 {
    width: 40% !important; }
  .w-md-45 {
    width: 45% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-55 {
    width: 55% !important; }
  .w-md-60 {
    width: 60% !important; }
  .w-md-65 {
    width: 65% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-80 {
    width: 80% !important; }
  .w-md-85 {
    width: 85% !important; }
  .w-md-90 {
    width: 90% !important; }
  .w-md-95 {
    width: 95% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .min-vh-md-50 {
    min-height: 50vh !important; }
  .min-vh-md-65 {
    min-height: 65vh !important; }
  .min-vh-md-75 {
    min-height: 75vh !important; }
  .min-vh-md-85 {
    min-height: 85vh !important; }
  .min-vh-md-100 {
    min-height: 100vh !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .m-md-6 {
    margin: 2.5rem !important; }
  .m-md-7 {
    margin: 3rem !important; }
  .m-md-8 {
    margin: 3.5rem !important; }
  .m-md-9 {
    margin: 4rem !important; }
  .m-md-10 {
    margin: 5rem !important; }
  .m-md-11 {
    margin: 6rem !important; }
  .m-md-12 {
    margin: 9rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-md-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-md-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-md-12 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-md-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-md-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-md-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 2rem !important; }
  .mt-md-6 {
    margin-top: 2.5rem !important; }
  .mt-md-7 {
    margin-top: 3rem !important; }
  .mt-md-8 {
    margin-top: 3.5rem !important; }
  .mt-md-9 {
    margin-top: 4rem !important; }
  .mt-md-10 {
    margin-top: 5rem !important; }
  .mt-md-11 {
    margin-top: 6rem !important; }
  .mt-md-12 {
    margin-top: 9rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 2rem !important; }
  .me-md-6 {
    margin-right: 2.5rem !important; }
  .me-md-7 {
    margin-right: 3rem !important; }
  .me-md-8 {
    margin-right: 3.5rem !important; }
  .me-md-9 {
    margin-right: 4rem !important; }
  .me-md-10 {
    margin-right: 5rem !important; }
  .me-md-11 {
    margin-right: 6rem !important; }
  .me-md-12 {
    margin-right: 9rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 2rem !important; }
  .mb-md-6 {
    margin-bottom: 2.5rem !important; }
  .mb-md-7 {
    margin-bottom: 3rem !important; }
  .mb-md-8 {
    margin-bottom: 3.5rem !important; }
  .mb-md-9 {
    margin-bottom: 4rem !important; }
  .mb-md-10 {
    margin-bottom: 5rem !important; }
  .mb-md-11 {
    margin-bottom: 6rem !important; }
  .mb-md-12 {
    margin-bottom: 9rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 2rem !important; }
  .ms-md-6 {
    margin-left: 2.5rem !important; }
  .ms-md-7 {
    margin-left: 3rem !important; }
  .ms-md-8 {
    margin-left: 3.5rem !important; }
  .ms-md-9 {
    margin-left: 4rem !important; }
  .ms-md-10 {
    margin-left: 5rem !important; }
  .ms-md-11 {
    margin-left: 6rem !important; }
  .ms-md-12 {
    margin-left: 9rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -2rem !important; }
  .m-md-n6 {
    margin: -2.5rem !important; }
  .m-md-n7 {
    margin: -3rem !important; }
  .m-md-n8 {
    margin: -3.5rem !important; }
  .m-md-n9 {
    margin: -4rem !important; }
  .m-md-n10 {
    margin: -5rem !important; }
  .m-md-n11 {
    margin: -6rem !important; }
  .m-md-n12 {
    margin: -9rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-md-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-md-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-md-n12 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-md-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-md-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-md-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -2rem !important; }
  .mt-md-n6 {
    margin-top: -2.5rem !important; }
  .mt-md-n7 {
    margin-top: -3rem !important; }
  .mt-md-n8 {
    margin-top: -3.5rem !important; }
  .mt-md-n9 {
    margin-top: -4rem !important; }
  .mt-md-n10 {
    margin-top: -5rem !important; }
  .mt-md-n11 {
    margin-top: -6rem !important; }
  .mt-md-n12 {
    margin-top: -9rem !important; }
  .me-md-n1 {
    margin-right: -0.25rem !important; }
  .me-md-n2 {
    margin-right: -0.5rem !important; }
  .me-md-n3 {
    margin-right: -1rem !important; }
  .me-md-n4 {
    margin-right: -1.5rem !important; }
  .me-md-n5 {
    margin-right: -2rem !important; }
  .me-md-n6 {
    margin-right: -2.5rem !important; }
  .me-md-n7 {
    margin-right: -3rem !important; }
  .me-md-n8 {
    margin-right: -3.5rem !important; }
  .me-md-n9 {
    margin-right: -4rem !important; }
  .me-md-n10 {
    margin-right: -5rem !important; }
  .me-md-n11 {
    margin-right: -6rem !important; }
  .me-md-n12 {
    margin-right: -9rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -2rem !important; }
  .mb-md-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-md-n7 {
    margin-bottom: -3rem !important; }
  .mb-md-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-md-n9 {
    margin-bottom: -4rem !important; }
  .mb-md-n10 {
    margin-bottom: -5rem !important; }
  .mb-md-n11 {
    margin-bottom: -6rem !important; }
  .mb-md-n12 {
    margin-bottom: -9rem !important; }
  .ms-md-n1 {
    margin-left: -0.25rem !important; }
  .ms-md-n2 {
    margin-left: -0.5rem !important; }
  .ms-md-n3 {
    margin-left: -1rem !important; }
  .ms-md-n4 {
    margin-left: -1.5rem !important; }
  .ms-md-n5 {
    margin-left: -2rem !important; }
  .ms-md-n6 {
    margin-left: -2.5rem !important; }
  .ms-md-n7 {
    margin-left: -3rem !important; }
  .ms-md-n8 {
    margin-left: -3.5rem !important; }
  .ms-md-n9 {
    margin-left: -4rem !important; }
  .ms-md-n10 {
    margin-left: -5rem !important; }
  .ms-md-n11 {
    margin-left: -6rem !important; }
  .ms-md-n12 {
    margin-left: -9rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .p-md-6 {
    padding: 2.5rem !important; }
  .p-md-7 {
    padding: 3rem !important; }
  .p-md-8 {
    padding: 3.5rem !important; }
  .p-md-9 {
    padding: 4rem !important; }
  .p-md-10 {
    padding: 5rem !important; }
  .p-md-11 {
    padding: 6rem !important; }
  .p-md-12 {
    padding: 9rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-md-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-md-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-md-12 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-md-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-md-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-md-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 2rem !important; }
  .pt-md-6 {
    padding-top: 2.5rem !important; }
  .pt-md-7 {
    padding-top: 3rem !important; }
  .pt-md-8 {
    padding-top: 3.5rem !important; }
  .pt-md-9 {
    padding-top: 4rem !important; }
  .pt-md-10 {
    padding-top: 5rem !important; }
  .pt-md-11 {
    padding-top: 6rem !important; }
  .pt-md-12 {
    padding-top: 9rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 2rem !important; }
  .pe-md-6 {
    padding-right: 2.5rem !important; }
  .pe-md-7 {
    padding-right: 3rem !important; }
  .pe-md-8 {
    padding-right: 3.5rem !important; }
  .pe-md-9 {
    padding-right: 4rem !important; }
  .pe-md-10 {
    padding-right: 5rem !important; }
  .pe-md-11 {
    padding-right: 6rem !important; }
  .pe-md-12 {
    padding-right: 9rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 2rem !important; }
  .pb-md-6 {
    padding-bottom: 2.5rem !important; }
  .pb-md-7 {
    padding-bottom: 3rem !important; }
  .pb-md-8 {
    padding-bottom: 3.5rem !important; }
  .pb-md-9 {
    padding-bottom: 4rem !important; }
  .pb-md-10 {
    padding-bottom: 5rem !important; }
  .pb-md-11 {
    padding-bottom: 6rem !important; }
  .pb-md-12 {
    padding-bottom: 9rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 2rem !important; }
  .ps-md-6 {
    padding-left: 2.5rem !important; }
  .ps-md-7 {
    padding-left: 3rem !important; }
  .ps-md-8 {
    padding-left: 3.5rem !important; }
  .ps-md-9 {
    padding-left: 4rem !important; }
  .ps-md-10 {
    padding-left: 5rem !important; }
  .ps-md-11 {
    padding-left: 6rem !important; }
  .ps-md-12 {
    padding-left: 9rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 2rem !important; }
  .gap-md-6 {
    gap: 2.5rem !important; }
  .gap-md-7 {
    gap: 3rem !important; }
  .gap-md-8 {
    gap: 3.5rem !important; }
  .gap-md-9 {
    gap: 4rem !important; }
  .gap-md-10 {
    gap: 5rem !important; }
  .gap-md-11 {
    gap: 6rem !important; }
  .gap-md-12 {
    gap: 9rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .letter-spacing-md-1 {
    letter-spacing: 1px !important; }
  .letter-spacing-md-2 {
    letter-spacing: 2px !important; }
  .letter-spacing-md-3 {
    letter-spacing: 3px !important; }
  .letter-spacing-md-4 {
    letter-spacing: 4px !important; }
  .letter-spacing-md-5 {
    letter-spacing: 5px !important; }
  .letter-spacing-md-6 {
    letter-spacing: 6px !important; }
  .letter-spacing-md-7 {
    letter-spacing: 7px !important; }
  .letter-spacing-md-8 {
    letter-spacing: 8px !important; }
  .letter-spacing-md-9 {
    letter-spacing: 9px !important; }
  .letter-spacing-md-10 {
    letter-spacing: 10px !important; }
  .img-md-auto {
    max-width: inherit !important; }
  .img-md-120 {
    max-width: 120% !important; }
  .img-md-140 {
    max-width: 140% !important; }
  .h-px-md-1 {
    height: 1px !important; }
  .h-px-md-5 {
    height: 5px !important; }
  .h-px-md-10 {
    height: 10px !important; }
  .h-px-md-100 {
    height: 100px !important; }
  .h-px-md-250 {
    height: 250px !important; }
  .h-px-md-300 {
    height: 300px !important; }
  .h-px-md-350 {
    height: 350px !important; }
  .min-h-px-md-250 {
    min-height: 250px !important; }
  .min-h-px-md-300 {
    min-height: 300px !important; }
  .min-h-px-md-350 {
    min-height: 350px !important; }
  .min-h-px-md-450 {
    min-height: 450px !important; }
  .min-h-px-md-550 {
    min-height: 550px !important; }
  .w-px-md-50 {
    width: 50px !important; }
  .w-px-md-100 {
    width: 100px !important; }
  .w-px-md-150 {
    width: 150px !important; }
  .w-px-md-200 {
    width: 200px !important; }
  .w-px-md-250 {
    width: 250px !important; }
  .w-px-md-300 {
    width: 300px !important; }
  .w-px-md-350 {
    width: 350px !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .w-lg-5 {
    width: 5% !important; }
  .w-lg-10 {
    width: 10% !important; }
  .w-lg-15 {
    width: 15% !important; }
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-30 {
    width: 30% !important; }
  .w-lg-35 {
    width: 35% !important; }
  .w-lg-40 {
    width: 40% !important; }
  .w-lg-45 {
    width: 45% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-55 {
    width: 55% !important; }
  .w-lg-60 {
    width: 60% !important; }
  .w-lg-65 {
    width: 65% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-80 {
    width: 80% !important; }
  .w-lg-85 {
    width: 85% !important; }
  .w-lg-90 {
    width: 90% !important; }
  .w-lg-95 {
    width: 95% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .min-vh-lg-50 {
    min-height: 50vh !important; }
  .min-vh-lg-65 {
    min-height: 65vh !important; }
  .min-vh-lg-75 {
    min-height: 75vh !important; }
  .min-vh-lg-85 {
    min-height: 85vh !important; }
  .min-vh-lg-100 {
    min-height: 100vh !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .m-lg-6 {
    margin: 2.5rem !important; }
  .m-lg-7 {
    margin: 3rem !important; }
  .m-lg-8 {
    margin: 3.5rem !important; }
  .m-lg-9 {
    margin: 4rem !important; }
  .m-lg-10 {
    margin: 5rem !important; }
  .m-lg-11 {
    margin: 6rem !important; }
  .m-lg-12 {
    margin: 9rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-lg-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-lg-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-lg-12 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-lg-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-lg-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-lg-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 2rem !important; }
  .mt-lg-6 {
    margin-top: 2.5rem !important; }
  .mt-lg-7 {
    margin-top: 3rem !important; }
  .mt-lg-8 {
    margin-top: 3.5rem !important; }
  .mt-lg-9 {
    margin-top: 4rem !important; }
  .mt-lg-10 {
    margin-top: 5rem !important; }
  .mt-lg-11 {
    margin-top: 6rem !important; }
  .mt-lg-12 {
    margin-top: 9rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 2rem !important; }
  .me-lg-6 {
    margin-right: 2.5rem !important; }
  .me-lg-7 {
    margin-right: 3rem !important; }
  .me-lg-8 {
    margin-right: 3.5rem !important; }
  .me-lg-9 {
    margin-right: 4rem !important; }
  .me-lg-10 {
    margin-right: 5rem !important; }
  .me-lg-11 {
    margin-right: 6rem !important; }
  .me-lg-12 {
    margin-right: 9rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 2rem !important; }
  .mb-lg-6 {
    margin-bottom: 2.5rem !important; }
  .mb-lg-7 {
    margin-bottom: 3rem !important; }
  .mb-lg-8 {
    margin-bottom: 3.5rem !important; }
  .mb-lg-9 {
    margin-bottom: 4rem !important; }
  .mb-lg-10 {
    margin-bottom: 5rem !important; }
  .mb-lg-11 {
    margin-bottom: 6rem !important; }
  .mb-lg-12 {
    margin-bottom: 9rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 2rem !important; }
  .ms-lg-6 {
    margin-left: 2.5rem !important; }
  .ms-lg-7 {
    margin-left: 3rem !important; }
  .ms-lg-8 {
    margin-left: 3.5rem !important; }
  .ms-lg-9 {
    margin-left: 4rem !important; }
  .ms-lg-10 {
    margin-left: 5rem !important; }
  .ms-lg-11 {
    margin-left: 6rem !important; }
  .ms-lg-12 {
    margin-left: 9rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -2rem !important; }
  .m-lg-n6 {
    margin: -2.5rem !important; }
  .m-lg-n7 {
    margin: -3rem !important; }
  .m-lg-n8 {
    margin: -3.5rem !important; }
  .m-lg-n9 {
    margin: -4rem !important; }
  .m-lg-n10 {
    margin: -5rem !important; }
  .m-lg-n11 {
    margin: -6rem !important; }
  .m-lg-n12 {
    margin: -9rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-lg-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-lg-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-lg-n12 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-lg-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-lg-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-lg-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -2rem !important; }
  .mt-lg-n6 {
    margin-top: -2.5rem !important; }
  .mt-lg-n7 {
    margin-top: -3rem !important; }
  .mt-lg-n8 {
    margin-top: -3.5rem !important; }
  .mt-lg-n9 {
    margin-top: -4rem !important; }
  .mt-lg-n10 {
    margin-top: -5rem !important; }
  .mt-lg-n11 {
    margin-top: -6rem !important; }
  .mt-lg-n12 {
    margin-top: -9rem !important; }
  .me-lg-n1 {
    margin-right: -0.25rem !important; }
  .me-lg-n2 {
    margin-right: -0.5rem !important; }
  .me-lg-n3 {
    margin-right: -1rem !important; }
  .me-lg-n4 {
    margin-right: -1.5rem !important; }
  .me-lg-n5 {
    margin-right: -2rem !important; }
  .me-lg-n6 {
    margin-right: -2.5rem !important; }
  .me-lg-n7 {
    margin-right: -3rem !important; }
  .me-lg-n8 {
    margin-right: -3.5rem !important; }
  .me-lg-n9 {
    margin-right: -4rem !important; }
  .me-lg-n10 {
    margin-right: -5rem !important; }
  .me-lg-n11 {
    margin-right: -6rem !important; }
  .me-lg-n12 {
    margin-right: -9rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -2rem !important; }
  .mb-lg-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-lg-n7 {
    margin-bottom: -3rem !important; }
  .mb-lg-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-lg-n9 {
    margin-bottom: -4rem !important; }
  .mb-lg-n10 {
    margin-bottom: -5rem !important; }
  .mb-lg-n11 {
    margin-bottom: -6rem !important; }
  .mb-lg-n12 {
    margin-bottom: -9rem !important; }
  .ms-lg-n1 {
    margin-left: -0.25rem !important; }
  .ms-lg-n2 {
    margin-left: -0.5rem !important; }
  .ms-lg-n3 {
    margin-left: -1rem !important; }
  .ms-lg-n4 {
    margin-left: -1.5rem !important; }
  .ms-lg-n5 {
    margin-left: -2rem !important; }
  .ms-lg-n6 {
    margin-left: -2.5rem !important; }
  .ms-lg-n7 {
    margin-left: -3rem !important; }
  .ms-lg-n8 {
    margin-left: -3.5rem !important; }
  .ms-lg-n9 {
    margin-left: -4rem !important; }
  .ms-lg-n10 {
    margin-left: -5rem !important; }
  .ms-lg-n11 {
    margin-left: -6rem !important; }
  .ms-lg-n12 {
    margin-left: -9rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .p-lg-6 {
    padding: 2.5rem !important; }
  .p-lg-7 {
    padding: 3rem !important; }
  .p-lg-8 {
    padding: 3.5rem !important; }
  .p-lg-9 {
    padding: 4rem !important; }
  .p-lg-10 {
    padding: 5rem !important; }
  .p-lg-11 {
    padding: 6rem !important; }
  .p-lg-12 {
    padding: 9rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-lg-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-lg-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-lg-12 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-lg-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-lg-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 2rem !important; }
  .pt-lg-6 {
    padding-top: 2.5rem !important; }
  .pt-lg-7 {
    padding-top: 3rem !important; }
  .pt-lg-8 {
    padding-top: 3.5rem !important; }
  .pt-lg-9 {
    padding-top: 4rem !important; }
  .pt-lg-10 {
    padding-top: 5rem !important; }
  .pt-lg-11 {
    padding-top: 6rem !important; }
  .pt-lg-12 {
    padding-top: 9rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 2rem !important; }
  .pe-lg-6 {
    padding-right: 2.5rem !important; }
  .pe-lg-7 {
    padding-right: 3rem !important; }
  .pe-lg-8 {
    padding-right: 3.5rem !important; }
  .pe-lg-9 {
    padding-right: 4rem !important; }
  .pe-lg-10 {
    padding-right: 5rem !important; }
  .pe-lg-11 {
    padding-right: 6rem !important; }
  .pe-lg-12 {
    padding-right: 9rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 2rem !important; }
  .pb-lg-6 {
    padding-bottom: 2.5rem !important; }
  .pb-lg-7 {
    padding-bottom: 3rem !important; }
  .pb-lg-8 {
    padding-bottom: 3.5rem !important; }
  .pb-lg-9 {
    padding-bottom: 4rem !important; }
  .pb-lg-10 {
    padding-bottom: 5rem !important; }
  .pb-lg-11 {
    padding-bottom: 6rem !important; }
  .pb-lg-12 {
    padding-bottom: 9rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 2rem !important; }
  .ps-lg-6 {
    padding-left: 2.5rem !important; }
  .ps-lg-7 {
    padding-left: 3rem !important; }
  .ps-lg-8 {
    padding-left: 3.5rem !important; }
  .ps-lg-9 {
    padding-left: 4rem !important; }
  .ps-lg-10 {
    padding-left: 5rem !important; }
  .ps-lg-11 {
    padding-left: 6rem !important; }
  .ps-lg-12 {
    padding-left: 9rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 2rem !important; }
  .gap-lg-6 {
    gap: 2.5rem !important; }
  .gap-lg-7 {
    gap: 3rem !important; }
  .gap-lg-8 {
    gap: 3.5rem !important; }
  .gap-lg-9 {
    gap: 4rem !important; }
  .gap-lg-10 {
    gap: 5rem !important; }
  .gap-lg-11 {
    gap: 6rem !important; }
  .gap-lg-12 {
    gap: 9rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .letter-spacing-lg-1 {
    letter-spacing: 1px !important; }
  .letter-spacing-lg-2 {
    letter-spacing: 2px !important; }
  .letter-spacing-lg-3 {
    letter-spacing: 3px !important; }
  .letter-spacing-lg-4 {
    letter-spacing: 4px !important; }
  .letter-spacing-lg-5 {
    letter-spacing: 5px !important; }
  .letter-spacing-lg-6 {
    letter-spacing: 6px !important; }
  .letter-spacing-lg-7 {
    letter-spacing: 7px !important; }
  .letter-spacing-lg-8 {
    letter-spacing: 8px !important; }
  .letter-spacing-lg-9 {
    letter-spacing: 9px !important; }
  .letter-spacing-lg-10 {
    letter-spacing: 10px !important; }
  .img-lg-auto {
    max-width: inherit !important; }
  .img-lg-120 {
    max-width: 120% !important; }
  .img-lg-140 {
    max-width: 140% !important; }
  .h-px-lg-1 {
    height: 1px !important; }
  .h-px-lg-5 {
    height: 5px !important; }
  .h-px-lg-10 {
    height: 10px !important; }
  .h-px-lg-100 {
    height: 100px !important; }
  .h-px-lg-250 {
    height: 250px !important; }
  .h-px-lg-300 {
    height: 300px !important; }
  .h-px-lg-350 {
    height: 350px !important; }
  .min-h-px-lg-250 {
    min-height: 250px !important; }
  .min-h-px-lg-300 {
    min-height: 300px !important; }
  .min-h-px-lg-350 {
    min-height: 350px !important; }
  .min-h-px-lg-450 {
    min-height: 450px !important; }
  .min-h-px-lg-550 {
    min-height: 550px !important; }
  .w-px-lg-50 {
    width: 50px !important; }
  .w-px-lg-100 {
    width: 100px !important; }
  .w-px-lg-150 {
    width: 150px !important; }
  .w-px-lg-200 {
    width: 200px !important; }
  .w-px-lg-250 {
    width: 250px !important; }
  .w-px-lg-300 {
    width: 300px !important; }
  .w-px-lg-350 {
    width: 350px !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .w-xl-5 {
    width: 5% !important; }
  .w-xl-10 {
    width: 10% !important; }
  .w-xl-15 {
    width: 15% !important; }
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-30 {
    width: 30% !important; }
  .w-xl-35 {
    width: 35% !important; }
  .w-xl-40 {
    width: 40% !important; }
  .w-xl-45 {
    width: 45% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-55 {
    width: 55% !important; }
  .w-xl-60 {
    width: 60% !important; }
  .w-xl-65 {
    width: 65% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-80 {
    width: 80% !important; }
  .w-xl-85 {
    width: 85% !important; }
  .w-xl-90 {
    width: 90% !important; }
  .w-xl-95 {
    width: 95% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .min-vh-xl-50 {
    min-height: 50vh !important; }
  .min-vh-xl-65 {
    min-height: 65vh !important; }
  .min-vh-xl-75 {
    min-height: 75vh !important; }
  .min-vh-xl-85 {
    min-height: 85vh !important; }
  .min-vh-xl-100 {
    min-height: 100vh !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .m-xl-6 {
    margin: 2.5rem !important; }
  .m-xl-7 {
    margin: 3rem !important; }
  .m-xl-8 {
    margin: 3.5rem !important; }
  .m-xl-9 {
    margin: 4rem !important; }
  .m-xl-10 {
    margin: 5rem !important; }
  .m-xl-11 {
    margin: 6rem !important; }
  .m-xl-12 {
    margin: 9rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xl-12 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xl-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 2rem !important; }
  .mt-xl-6 {
    margin-top: 2.5rem !important; }
  .mt-xl-7 {
    margin-top: 3rem !important; }
  .mt-xl-8 {
    margin-top: 3.5rem !important; }
  .mt-xl-9 {
    margin-top: 4rem !important; }
  .mt-xl-10 {
    margin-top: 5rem !important; }
  .mt-xl-11 {
    margin-top: 6rem !important; }
  .mt-xl-12 {
    margin-top: 9rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 2rem !important; }
  .me-xl-6 {
    margin-right: 2.5rem !important; }
  .me-xl-7 {
    margin-right: 3rem !important; }
  .me-xl-8 {
    margin-right: 3.5rem !important; }
  .me-xl-9 {
    margin-right: 4rem !important; }
  .me-xl-10 {
    margin-right: 5rem !important; }
  .me-xl-11 {
    margin-right: 6rem !important; }
  .me-xl-12 {
    margin-right: 9rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 2rem !important; }
  .mb-xl-6 {
    margin-bottom: 2.5rem !important; }
  .mb-xl-7 {
    margin-bottom: 3rem !important; }
  .mb-xl-8 {
    margin-bottom: 3.5rem !important; }
  .mb-xl-9 {
    margin-bottom: 4rem !important; }
  .mb-xl-10 {
    margin-bottom: 5rem !important; }
  .mb-xl-11 {
    margin-bottom: 6rem !important; }
  .mb-xl-12 {
    margin-bottom: 9rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 2rem !important; }
  .ms-xl-6 {
    margin-left: 2.5rem !important; }
  .ms-xl-7 {
    margin-left: 3rem !important; }
  .ms-xl-8 {
    margin-left: 3.5rem !important; }
  .ms-xl-9 {
    margin-left: 4rem !important; }
  .ms-xl-10 {
    margin-left: 5rem !important; }
  .ms-xl-11 {
    margin-left: 6rem !important; }
  .ms-xl-12 {
    margin-left: 9rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -2rem !important; }
  .m-xl-n6 {
    margin: -2.5rem !important; }
  .m-xl-n7 {
    margin: -3rem !important; }
  .m-xl-n8 {
    margin: -3.5rem !important; }
  .m-xl-n9 {
    margin: -4rem !important; }
  .m-xl-n10 {
    margin: -5rem !important; }
  .m-xl-n11 {
    margin: -6rem !important; }
  .m-xl-n12 {
    margin: -9rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-xl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-xl-n12 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-xl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-xl-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -2rem !important; }
  .mt-xl-n6 {
    margin-top: -2.5rem !important; }
  .mt-xl-n7 {
    margin-top: -3rem !important; }
  .mt-xl-n8 {
    margin-top: -3.5rem !important; }
  .mt-xl-n9 {
    margin-top: -4rem !important; }
  .mt-xl-n10 {
    margin-top: -5rem !important; }
  .mt-xl-n11 {
    margin-top: -6rem !important; }
  .mt-xl-n12 {
    margin-top: -9rem !important; }
  .me-xl-n1 {
    margin-right: -0.25rem !important; }
  .me-xl-n2 {
    margin-right: -0.5rem !important; }
  .me-xl-n3 {
    margin-right: -1rem !important; }
  .me-xl-n4 {
    margin-right: -1.5rem !important; }
  .me-xl-n5 {
    margin-right: -2rem !important; }
  .me-xl-n6 {
    margin-right: -2.5rem !important; }
  .me-xl-n7 {
    margin-right: -3rem !important; }
  .me-xl-n8 {
    margin-right: -3.5rem !important; }
  .me-xl-n9 {
    margin-right: -4rem !important; }
  .me-xl-n10 {
    margin-right: -5rem !important; }
  .me-xl-n11 {
    margin-right: -6rem !important; }
  .me-xl-n12 {
    margin-right: -9rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -2rem !important; }
  .mb-xl-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-xl-n7 {
    margin-bottom: -3rem !important; }
  .mb-xl-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-xl-n9 {
    margin-bottom: -4rem !important; }
  .mb-xl-n10 {
    margin-bottom: -5rem !important; }
  .mb-xl-n11 {
    margin-bottom: -6rem !important; }
  .mb-xl-n12 {
    margin-bottom: -9rem !important; }
  .ms-xl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xl-n3 {
    margin-left: -1rem !important; }
  .ms-xl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xl-n5 {
    margin-left: -2rem !important; }
  .ms-xl-n6 {
    margin-left: -2.5rem !important; }
  .ms-xl-n7 {
    margin-left: -3rem !important; }
  .ms-xl-n8 {
    margin-left: -3.5rem !important; }
  .ms-xl-n9 {
    margin-left: -4rem !important; }
  .ms-xl-n10 {
    margin-left: -5rem !important; }
  .ms-xl-n11 {
    margin-left: -6rem !important; }
  .ms-xl-n12 {
    margin-left: -9rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .p-xl-6 {
    padding: 2.5rem !important; }
  .p-xl-7 {
    padding: 3rem !important; }
  .p-xl-8 {
    padding: 3.5rem !important; }
  .p-xl-9 {
    padding: 4rem !important; }
  .p-xl-10 {
    padding: 5rem !important; }
  .p-xl-11 {
    padding: 6rem !important; }
  .p-xl-12 {
    padding: 9rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xl-12 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xl-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 2rem !important; }
  .pt-xl-6 {
    padding-top: 2.5rem !important; }
  .pt-xl-7 {
    padding-top: 3rem !important; }
  .pt-xl-8 {
    padding-top: 3.5rem !important; }
  .pt-xl-9 {
    padding-top: 4rem !important; }
  .pt-xl-10 {
    padding-top: 5rem !important; }
  .pt-xl-11 {
    padding-top: 6rem !important; }
  .pt-xl-12 {
    padding-top: 9rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 2rem !important; }
  .pe-xl-6 {
    padding-right: 2.5rem !important; }
  .pe-xl-7 {
    padding-right: 3rem !important; }
  .pe-xl-8 {
    padding-right: 3.5rem !important; }
  .pe-xl-9 {
    padding-right: 4rem !important; }
  .pe-xl-10 {
    padding-right: 5rem !important; }
  .pe-xl-11 {
    padding-right: 6rem !important; }
  .pe-xl-12 {
    padding-right: 9rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 2rem !important; }
  .pb-xl-6 {
    padding-bottom: 2.5rem !important; }
  .pb-xl-7 {
    padding-bottom: 3rem !important; }
  .pb-xl-8 {
    padding-bottom: 3.5rem !important; }
  .pb-xl-9 {
    padding-bottom: 4rem !important; }
  .pb-xl-10 {
    padding-bottom: 5rem !important; }
  .pb-xl-11 {
    padding-bottom: 6rem !important; }
  .pb-xl-12 {
    padding-bottom: 9rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 2rem !important; }
  .ps-xl-6 {
    padding-left: 2.5rem !important; }
  .ps-xl-7 {
    padding-left: 3rem !important; }
  .ps-xl-8 {
    padding-left: 3.5rem !important; }
  .ps-xl-9 {
    padding-left: 4rem !important; }
  .ps-xl-10 {
    padding-left: 5rem !important; }
  .ps-xl-11 {
    padding-left: 6rem !important; }
  .ps-xl-12 {
    padding-left: 9rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 2rem !important; }
  .gap-xl-6 {
    gap: 2.5rem !important; }
  .gap-xl-7 {
    gap: 3rem !important; }
  .gap-xl-8 {
    gap: 3.5rem !important; }
  .gap-xl-9 {
    gap: 4rem !important; }
  .gap-xl-10 {
    gap: 5rem !important; }
  .gap-xl-11 {
    gap: 6rem !important; }
  .gap-xl-12 {
    gap: 9rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .letter-spacing-xl-1 {
    letter-spacing: 1px !important; }
  .letter-spacing-xl-2 {
    letter-spacing: 2px !important; }
  .letter-spacing-xl-3 {
    letter-spacing: 3px !important; }
  .letter-spacing-xl-4 {
    letter-spacing: 4px !important; }
  .letter-spacing-xl-5 {
    letter-spacing: 5px !important; }
  .letter-spacing-xl-6 {
    letter-spacing: 6px !important; }
  .letter-spacing-xl-7 {
    letter-spacing: 7px !important; }
  .letter-spacing-xl-8 {
    letter-spacing: 8px !important; }
  .letter-spacing-xl-9 {
    letter-spacing: 9px !important; }
  .letter-spacing-xl-10 {
    letter-spacing: 10px !important; }
  .img-xl-auto {
    max-width: inherit !important; }
  .img-xl-120 {
    max-width: 120% !important; }
  .img-xl-140 {
    max-width: 140% !important; }
  .h-px-xl-1 {
    height: 1px !important; }
  .h-px-xl-5 {
    height: 5px !important; }
  .h-px-xl-10 {
    height: 10px !important; }
  .h-px-xl-100 {
    height: 100px !important; }
  .h-px-xl-250 {
    height: 250px !important; }
  .h-px-xl-300 {
    height: 300px !important; }
  .h-px-xl-350 {
    height: 350px !important; }
  .min-h-px-xl-250 {
    min-height: 250px !important; }
  .min-h-px-xl-300 {
    min-height: 300px !important; }
  .min-h-px-xl-350 {
    min-height: 350px !important; }
  .min-h-px-xl-450 {
    min-height: 450px !important; }
  .min-h-px-xl-550 {
    min-height: 550px !important; }
  .w-px-xl-50 {
    width: 50px !important; }
  .w-px-xl-100 {
    width: 100px !important; }
  .w-px-xl-150 {
    width: 150px !important; }
  .w-px-xl-200 {
    width: 200px !important; }
  .w-px-xl-250 {
    width: 250px !important; }
  .w-px-xl-300 {
    width: 300px !important; }
  .w-px-xl-350 {
    width: 350px !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .w-xxl-5 {
    width: 5% !important; }
  .w-xxl-10 {
    width: 10% !important; }
  .w-xxl-15 {
    width: 15% !important; }
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-30 {
    width: 30% !important; }
  .w-xxl-35 {
    width: 35% !important; }
  .w-xxl-40 {
    width: 40% !important; }
  .w-xxl-45 {
    width: 45% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-55 {
    width: 55% !important; }
  .w-xxl-60 {
    width: 60% !important; }
  .w-xxl-65 {
    width: 65% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-80 {
    width: 80% !important; }
  .w-xxl-85 {
    width: 85% !important; }
  .w-xxl-90 {
    width: 90% !important; }
  .w-xxl-95 {
    width: 95% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .w-xxl-auto {
    width: auto !important; }
  .min-vh-xxl-50 {
    min-height: 50vh !important; }
  .min-vh-xxl-65 {
    min-height: 65vh !important; }
  .min-vh-xxl-75 {
    min-height: 75vh !important; }
  .min-vh-xxl-85 {
    min-height: 85vh !important; }
  .min-vh-xxl-100 {
    min-height: 100vh !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 2rem !important; }
  .m-xxl-6 {
    margin: 2.5rem !important; }
  .m-xxl-7 {
    margin: 3rem !important; }
  .m-xxl-8 {
    margin: 3.5rem !important; }
  .m-xxl-9 {
    margin: 4rem !important; }
  .m-xxl-10 {
    margin: 5rem !important; }
  .m-xxl-11 {
    margin: 6rem !important; }
  .m-xxl-12 {
    margin: 9rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xxl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxl-12 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xxl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxl-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 2rem !important; }
  .mt-xxl-6 {
    margin-top: 2.5rem !important; }
  .mt-xxl-7 {
    margin-top: 3rem !important; }
  .mt-xxl-8 {
    margin-top: 3.5rem !important; }
  .mt-xxl-9 {
    margin-top: 4rem !important; }
  .mt-xxl-10 {
    margin-top: 5rem !important; }
  .mt-xxl-11 {
    margin-top: 6rem !important; }
  .mt-xxl-12 {
    margin-top: 9rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 2rem !important; }
  .me-xxl-6 {
    margin-right: 2.5rem !important; }
  .me-xxl-7 {
    margin-right: 3rem !important; }
  .me-xxl-8 {
    margin-right: 3.5rem !important; }
  .me-xxl-9 {
    margin-right: 4rem !important; }
  .me-xxl-10 {
    margin-right: 5rem !important; }
  .me-xxl-11 {
    margin-right: 6rem !important; }
  .me-xxl-12 {
    margin-right: 9rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 2rem !important; }
  .mb-xxl-6 {
    margin-bottom: 2.5rem !important; }
  .mb-xxl-7 {
    margin-bottom: 3rem !important; }
  .mb-xxl-8 {
    margin-bottom: 3.5rem !important; }
  .mb-xxl-9 {
    margin-bottom: 4rem !important; }
  .mb-xxl-10 {
    margin-bottom: 5rem !important; }
  .mb-xxl-11 {
    margin-bottom: 6rem !important; }
  .mb-xxl-12 {
    margin-bottom: 9rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 2rem !important; }
  .ms-xxl-6 {
    margin-left: 2.5rem !important; }
  .ms-xxl-7 {
    margin-left: 3rem !important; }
  .ms-xxl-8 {
    margin-left: 3.5rem !important; }
  .ms-xxl-9 {
    margin-left: 4rem !important; }
  .ms-xxl-10 {
    margin-left: 5rem !important; }
  .ms-xxl-11 {
    margin-left: 6rem !important; }
  .ms-xxl-12 {
    margin-left: 9rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -2rem !important; }
  .m-xxl-n6 {
    margin: -2.5rem !important; }
  .m-xxl-n7 {
    margin: -3rem !important; }
  .m-xxl-n8 {
    margin: -3.5rem !important; }
  .m-xxl-n9 {
    margin: -4rem !important; }
  .m-xxl-n10 {
    margin: -5rem !important; }
  .m-xxl-n11 {
    margin: -6rem !important; }
  .m-xxl-n12 {
    margin: -9rem !important; }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-xxl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xxl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-xxl-n12 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-xxl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xxl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-xxl-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xxl-n3 {
    margin-top: -1rem !important; }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xxl-n5 {
    margin-top: -2rem !important; }
  .mt-xxl-n6 {
    margin-top: -2.5rem !important; }
  .mt-xxl-n7 {
    margin-top: -3rem !important; }
  .mt-xxl-n8 {
    margin-top: -3.5rem !important; }
  .mt-xxl-n9 {
    margin-top: -4rem !important; }
  .mt-xxl-n10 {
    margin-top: -5rem !important; }
  .mt-xxl-n11 {
    margin-top: -6rem !important; }
  .mt-xxl-n12 {
    margin-top: -9rem !important; }
  .me-xxl-n1 {
    margin-right: -0.25rem !important; }
  .me-xxl-n2 {
    margin-right: -0.5rem !important; }
  .me-xxl-n3 {
    margin-right: -1rem !important; }
  .me-xxl-n4 {
    margin-right: -1.5rem !important; }
  .me-xxl-n5 {
    margin-right: -2rem !important; }
  .me-xxl-n6 {
    margin-right: -2.5rem !important; }
  .me-xxl-n7 {
    margin-right: -3rem !important; }
  .me-xxl-n8 {
    margin-right: -3.5rem !important; }
  .me-xxl-n9 {
    margin-right: -4rem !important; }
  .me-xxl-n10 {
    margin-right: -5rem !important; }
  .me-xxl-n11 {
    margin-right: -6rem !important; }
  .me-xxl-n12 {
    margin-right: -9rem !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -2rem !important; }
  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-xxl-n7 {
    margin-bottom: -3rem !important; }
  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-xxl-n9 {
    margin-bottom: -4rem !important; }
  .mb-xxl-n10 {
    margin-bottom: -5rem !important; }
  .mb-xxl-n11 {
    margin-bottom: -6rem !important; }
  .mb-xxl-n12 {
    margin-bottom: -9rem !important; }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xxl-n3 {
    margin-left: -1rem !important; }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xxl-n5 {
    margin-left: -2rem !important; }
  .ms-xxl-n6 {
    margin-left: -2.5rem !important; }
  .ms-xxl-n7 {
    margin-left: -3rem !important; }
  .ms-xxl-n8 {
    margin-left: -3.5rem !important; }
  .ms-xxl-n9 {
    margin-left: -4rem !important; }
  .ms-xxl-n10 {
    margin-left: -5rem !important; }
  .ms-xxl-n11 {
    margin-left: -6rem !important; }
  .ms-xxl-n12 {
    margin-left: -9rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 2rem !important; }
  .p-xxl-6 {
    padding: 2.5rem !important; }
  .p-xxl-7 {
    padding: 3rem !important; }
  .p-xxl-8 {
    padding: 3.5rem !important; }
  .p-xxl-9 {
    padding: 4rem !important; }
  .p-xxl-10 {
    padding: 5rem !important; }
  .p-xxl-11 {
    padding: 6rem !important; }
  .p-xxl-12 {
    padding: 9rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xxl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xxl-12 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xxl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xxl-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 2rem !important; }
  .pt-xxl-6 {
    padding-top: 2.5rem !important; }
  .pt-xxl-7 {
    padding-top: 3rem !important; }
  .pt-xxl-8 {
    padding-top: 3.5rem !important; }
  .pt-xxl-9 {
    padding-top: 4rem !important; }
  .pt-xxl-10 {
    padding-top: 5rem !important; }
  .pt-xxl-11 {
    padding-top: 6rem !important; }
  .pt-xxl-12 {
    padding-top: 9rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 2rem !important; }
  .pe-xxl-6 {
    padding-right: 2.5rem !important; }
  .pe-xxl-7 {
    padding-right: 3rem !important; }
  .pe-xxl-8 {
    padding-right: 3.5rem !important; }
  .pe-xxl-9 {
    padding-right: 4rem !important; }
  .pe-xxl-10 {
    padding-right: 5rem !important; }
  .pe-xxl-11 {
    padding-right: 6rem !important; }
  .pe-xxl-12 {
    padding-right: 9rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 2rem !important; }
  .pb-xxl-6 {
    padding-bottom: 2.5rem !important; }
  .pb-xxl-7 {
    padding-bottom: 3rem !important; }
  .pb-xxl-8 {
    padding-bottom: 3.5rem !important; }
  .pb-xxl-9 {
    padding-bottom: 4rem !important; }
  .pb-xxl-10 {
    padding-bottom: 5rem !important; }
  .pb-xxl-11 {
    padding-bottom: 6rem !important; }
  .pb-xxl-12 {
    padding-bottom: 9rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 2rem !important; }
  .ps-xxl-6 {
    padding-left: 2.5rem !important; }
  .ps-xxl-7 {
    padding-left: 3rem !important; }
  .ps-xxl-8 {
    padding-left: 3.5rem !important; }
  .ps-xxl-9 {
    padding-left: 4rem !important; }
  .ps-xxl-10 {
    padding-left: 5rem !important; }
  .ps-xxl-11 {
    padding-left: 6rem !important; }
  .ps-xxl-12 {
    padding-left: 9rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 2rem !important; }
  .gap-xxl-6 {
    gap: 2.5rem !important; }
  .gap-xxl-7 {
    gap: 3rem !important; }
  .gap-xxl-8 {
    gap: 3.5rem !important; }
  .gap-xxl-9 {
    gap: 4rem !important; }
  .gap-xxl-10 {
    gap: 5rem !important; }
  .gap-xxl-11 {
    gap: 6rem !important; }
  .gap-xxl-12 {
    gap: 9rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; }
  .letter-spacing-xxl-1 {
    letter-spacing: 1px !important; }
  .letter-spacing-xxl-2 {
    letter-spacing: 2px !important; }
  .letter-spacing-xxl-3 {
    letter-spacing: 3px !important; }
  .letter-spacing-xxl-4 {
    letter-spacing: 4px !important; }
  .letter-spacing-xxl-5 {
    letter-spacing: 5px !important; }
  .letter-spacing-xxl-6 {
    letter-spacing: 6px !important; }
  .letter-spacing-xxl-7 {
    letter-spacing: 7px !important; }
  .letter-spacing-xxl-8 {
    letter-spacing: 8px !important; }
  .letter-spacing-xxl-9 {
    letter-spacing: 9px !important; }
  .letter-spacing-xxl-10 {
    letter-spacing: 10px !important; }
  .img-xxl-auto {
    max-width: inherit !important; }
  .img-xxl-120 {
    max-width: 120% !important; }
  .img-xxl-140 {
    max-width: 140% !important; }
  .h-px-xxl-1 {
    height: 1px !important; }
  .h-px-xxl-5 {
    height: 5px !important; }
  .h-px-xxl-10 {
    height: 10px !important; }
  .h-px-xxl-100 {
    height: 100px !important; }
  .h-px-xxl-250 {
    height: 250px !important; }
  .h-px-xxl-300 {
    height: 300px !important; }
  .h-px-xxl-350 {
    height: 350px !important; }
  .min-h-px-xxl-250 {
    min-height: 250px !important; }
  .min-h-px-xxl-300 {
    min-height: 300px !important; }
  .min-h-px-xxl-350 {
    min-height: 350px !important; }
  .min-h-px-xxl-450 {
    min-height: 450px !important; }
  .min-h-px-xxl-550 {
    min-height: 550px !important; }
  .w-px-xxl-50 {
    width: 50px !important; }
  .w-px-xxl-100 {
    width: 100px !important; }
  .w-px-xxl-150 {
    width: 150px !important; }
  .w-px-xxl-200 {
    width: 200px !important; }
  .w-px-xxl-250 {
    width: 250px !important; }
  .w-px-xxl-300 {
    width: 300px !important; }
  .w-px-xxl-350 {
    width: 350px !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

:root {
  --bs-nav-link-font-size: 1rem; }

.main-header-01.headroom--not-top {
  box-shadow: 0px 2px 4px -1px rgba(var(--bs-dark-rgb), 0.1), 0px 2px 4px -2px rgba(var(--bs-dark-rgb), 0.1); }

@media (max-width: 575.98px) {
  .main-header-01 .navbar {
    --bs-navbar-padding-y: 0; }
  .main-header-01 .navbar-brand {
    margin: 8px auto; } }

.main-header-01 .header-right {
  display: flex; }
  @media (max-width: 575.98px) {
    .main-header-01 .header-right {
      width: 100%;
      padding: 8px 0;
      border-top: 1px solid var(--bs-gray-300); }
      .main-header-01 .header-right .h-toggle {
        margin-left: auto; } }
  .main-header-01 .header-right .hr-col + .hr-col {
    padding-left: 15px; }
  .main-header-01 .header-right .h-icon-i {
    font-size: 1.2rem; }
  .main-header-01 .header-right .h-icon {
    color: var(--bs-dark);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    @media (max-width: 1199.98px) {
      .main-header-01 .header-right .h-icon {
        width: 40px;
        height: 40px; }
        .main-header-01 .header-right .h-icon .h-icon-i {
          font-size: 1.4rem; } }
    .main-header-01 .header-right .h-icon:hover {
      color: var(--bs-primary); }
    .main-header-01 .header-right .h-icon sup {
      min-width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--bs-primary);
      color: var(--bs-white);
      font-size: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0; }
    .main-header-01 .header-right .h-icon .h-icon-text {
      font-size: 0.80rem;
      padding-left: 5px; }
  .main-header-01 .header-right .dropdown-toggle:after {
    display: none; }

.main-header-01 .header-search {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  border-top: 1px solid var(--bs-gray-300);
  background: var(--bs-white);
  box-shadow: 0px 2px 4px -1px rgba(var(--bs-dark-rgb), 0.1), 0px 2px 4px -2px rgba(var(--bs-dark-rgb), 0.1); }
  .main-header-01 .header-search .header-search-in {
    padding: 1.2rem; }
  .main-header-01 .header-search form {
    display: flex; }
  .main-header-01 .header-search .form-control {
    border: 1px solid var(--bs-gray-400); }
  .main-header-01 .header-search .search-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: none;
    border: none;
    padding: 0;
    width: 40px;
    border-left: 1px solid var(--bs-gray-400); }

.main-header-01 .navbar-toggler {
  padding: 0;
  width: 35px;
  height: 35px;
  position: relative;
  border: none;
  color: rgba(var(--bs-dark-rgb), 1);
  margin-left: 10px; }
  .main-header-01 .navbar-toggler .toggler-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 17px;
    box-shadow: 0 0 0 1px currentColor, 0px -7px 0 1px currentColor, 0 7px 0 1px currentColor;
    height: 0; }

@media (max-width: 575.98px) {
  .navbar-expand-sm .offcanvas-header {
    border-bottom: 1px solid var(--bs-gray-300); }
  .navbar-expand-sm > .container {
    position: relative; }
  .navbar-expand-sm .navbar-nav > .nav-item {
    border-bottom: 1px solid var(--bs-gray-300); }
    .navbar-expand-sm .navbar-nav > .nav-item > .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .navbar-expand-sm .dropdown-menu {
    border: none;
    border-left: 1px solid var(--bs-gray-300);
    padding: 0 0 0 5px;
    margin: 0 0 15px;
    border-radius: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    --bs-navbar-padding-y: 0; }
    .navbar-expand-sm > .container {
      position: relative; }
    .navbar-expand-sm .px-dropdown-toggle {
      display: none; }
    .navbar-expand-sm .navbar-nav .nav-link {
      white-space: nowrap;
      --bs-nav-link-color: var(--bs-dark);
      --bs-nav-link-font-weight: 400;
      --bs-nav-link-padding-y: 1.4rem;
      --bs-navbar-nav-link-padding-x: 0.7rem; }
    .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .nav-link:after {
      content: "\f282";
      display: inline-block;
      font-family: bootstrap-icons !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: -.125em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: currentColor;
      font-size: 75%;
      margin-left: 7px;
      border: none; }
    .navbar-expand-sm .navbar-nav > .nav-item.dropdown.dropdown-full {
      position: static; }
    .navbar-expand-sm .navbar-nav > .nav-item:hover > .dropdown-menu {
      opacity: 1;
      pointer-events: auto; }
    .navbar-expand-sm .navbar-nav .px-mega-dropdown {
      position: static; }
    .navbar-expand-sm .navbar-nav .dropdown-mega-menu {
      width: 100%; }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: ease all 0.35s;
      margin: 0;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: 0px 10px 15px -3px rgba(var(--bs-dark-rgb), 0.05), 0px 4px 6px -4px rgba(var(--bs-dark-rgb), 0.05);
      border-top: 2px solid #B96F4A; }
      .navbar-expand-sm .navbar-nav .dropdown-menu.start {
        left: 0;
        transform: translate(0, 0); }
      .navbar-expand-sm .navbar-nav .dropdown-menu.end {
        left: auto;
        right: 0;
        transform: translate(0, 0); }
      .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
        position: relative;
        padding-right: 18px; }
        .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
          content: "\f285";
          display: inline-block;
          font-family: bootstrap-icons !important;
          font-style: normal;
          font-weight: normal !important;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          vertical-align: -.125em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: currentColor;
          font-size: 85%;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 10px;
          height: 10px; }
      .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
        opacity: 1;
        pointer-events: auto; }
      .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown-menu-sub {
        position: absolute;
        top: 0;
        left: 100%;
        opacity: 0;
        pointer-events: none;
        transform: translate(0, 0); }
        .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown-menu-sub.end {
          left: auto;
          right: 100%; }
      .navbar-expand-sm .navbar-nav .dropdown-menu.dropdown-menu-md {
        min-width: 22rem; }
      .navbar-expand-sm .navbar-nav .dropdown-menu.dropdown-menu-lg {
        min-width: 35rem; }
      .navbar-expand-sm .navbar-nav .dropdown-menu.dropdown-menu-xl {
        min-width: 42rem; }
      .navbar-expand-sm .navbar-nav .dropdown-menu.px-mega-menu {
        width: 100%; }
      .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown-item {
        padding: 0.4rem 1rem; } }

@media (max-width: 767.98px) {
  .navbar-expand-md .offcanvas-header {
    border-bottom: 1px solid var(--bs-gray-300); }
  .navbar-expand-md > .container {
    position: relative; }
  .navbar-expand-md .navbar-nav > .nav-item {
    border-bottom: 1px solid var(--bs-gray-300); }
    .navbar-expand-md .navbar-nav > .nav-item > .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .navbar-expand-md .dropdown-menu {
    border: none;
    border-left: 1px solid var(--bs-gray-300);
    padding: 0 0 0 5px;
    margin: 0 0 15px;
    border-radius: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    --bs-navbar-padding-y: 0; }
    .navbar-expand-md > .container {
      position: relative; }
    .navbar-expand-md .px-dropdown-toggle {
      display: none; }
    .navbar-expand-md .navbar-nav .nav-link {
      white-space: nowrap;
      --bs-nav-link-color: var(--bs-dark);
      --bs-nav-link-font-weight: 400;
      --bs-nav-link-padding-y: 1.4rem;
      --bs-navbar-nav-link-padding-x: 0.7rem; }
    .navbar-expand-md .navbar-nav > .nav-item.dropdown > .nav-link:after {
      content: "\f282";
      display: inline-block;
      font-family: bootstrap-icons !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: -.125em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: currentColor;
      font-size: 75%;
      margin-left: 7px;
      border: none; }
    .navbar-expand-md .navbar-nav > .nav-item.dropdown.dropdown-full {
      position: static; }
    .navbar-expand-md .navbar-nav > .nav-item:hover > .dropdown-menu {
      opacity: 1;
      pointer-events: auto; }
    .navbar-expand-md .navbar-nav .px-mega-dropdown {
      position: static; }
    .navbar-expand-md .navbar-nav .dropdown-mega-menu {
      width: 100%; }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: ease all 0.35s;
      margin: 0;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: 0px 10px 15px -3px rgba(var(--bs-dark-rgb), 0.05), 0px 4px 6px -4px rgba(var(--bs-dark-rgb), 0.05);
      border-top: 2px solid #B96F4A; }
      .navbar-expand-md .navbar-nav .dropdown-menu.start {
        left: 0;
        transform: translate(0, 0); }
      .navbar-expand-md .navbar-nav .dropdown-menu.end {
        left: auto;
        right: 0;
        transform: translate(0, 0); }
      .navbar-expand-md .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
        position: relative;
        padding-right: 18px; }
        .navbar-expand-md .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
          content: "\f285";
          display: inline-block;
          font-family: bootstrap-icons !important;
          font-style: normal;
          font-weight: normal !important;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          vertical-align: -.125em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: currentColor;
          font-size: 85%;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 10px;
          height: 10px; }
      .navbar-expand-md .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
        opacity: 1;
        pointer-events: auto; }
      .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-menu-sub {
        position: absolute;
        top: 0;
        left: 100%;
        opacity: 0;
        pointer-events: none;
        transform: translate(0, 0); }
        .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-menu-sub.end {
          left: auto;
          right: 100%; }
      .navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-md {
        min-width: 22rem; }
      .navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-lg {
        min-width: 35rem; }
      .navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-xl {
        min-width: 42rem; }
      .navbar-expand-md .navbar-nav .dropdown-menu.px-mega-menu {
        width: 100%; }
      .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-item {
        padding: 0.4rem 1rem; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg .offcanvas-header {
    border-bottom: 1px solid var(--bs-gray-300); }
  .navbar-expand-lg > .container {
    position: relative; }
  .navbar-expand-lg .navbar-nav > .nav-item {
    border-bottom: 1px solid var(--bs-gray-300); }
    .navbar-expand-lg .navbar-nav > .nav-item > .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .navbar-expand-lg .dropdown-menu {
    border: none;
    border-left: 1px solid var(--bs-gray-300);
    padding: 0 0 0 5px;
    margin: 0 0 15px;
    border-radius: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    --bs-navbar-padding-y: 0; }
    .navbar-expand-lg > .container {
      position: relative; }
    .navbar-expand-lg .px-dropdown-toggle {
      display: none; }
    .navbar-expand-lg .navbar-nav .nav-link {
      white-space: nowrap;
      --bs-nav-link-color: var(--bs-dark);
      --bs-nav-link-font-weight: 400;
      --bs-nav-link-padding-y: 1.4rem;
      --bs-navbar-nav-link-padding-x: 0.7rem; }
    .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .nav-link:after {
      content: "\f282";
      display: inline-block;
      font-family: bootstrap-icons !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: -.125em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: currentColor;
      font-size: 75%;
      margin-left: 7px;
      border: none; }
    .navbar-expand-lg .navbar-nav > .nav-item.dropdown.dropdown-full {
      position: static; }
    .navbar-expand-lg .navbar-nav > .nav-item:hover > .dropdown-menu {
      opacity: 1;
      pointer-events: auto; }
    .navbar-expand-lg .navbar-nav .px-mega-dropdown {
      position: static; }
    .navbar-expand-lg .navbar-nav .dropdown-mega-menu {
      width: 100%; }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: ease all 0.35s;
      margin: 0;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: 0px 10px 15px -3px rgba(var(--bs-dark-rgb), 0.05), 0px 4px 6px -4px rgba(var(--bs-dark-rgb), 0.05);
      border-top: 2px solid #B96F4A; }
      .navbar-expand-lg .navbar-nav .dropdown-menu.start {
        left: 0;
        transform: translate(0, 0); }
      .navbar-expand-lg .navbar-nav .dropdown-menu.end {
        left: auto;
        right: 0;
        transform: translate(0, 0); }
      .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
        position: relative;
        padding-right: 18px; }
        .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
          content: "\f285";
          display: inline-block;
          font-family: bootstrap-icons !important;
          font-style: normal;
          font-weight: normal !important;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          vertical-align: -.125em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: currentColor;
          font-size: 85%;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 10px;
          height: 10px; }
      .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
        opacity: 1;
        pointer-events: auto; }
      .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-sub {
        position: absolute;
        top: 0;
        left: 100%;
        opacity: 0;
        pointer-events: none;
        transform: translate(0, 0); }
        .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-sub.end {
          left: auto;
          right: 100%; }
      .navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-md {
        min-width: 22rem; }
      .navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-lg {
        min-width: 35rem; }
      .navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-xl {
        min-width: 42rem; }
      .navbar-expand-lg .navbar-nav .dropdown-menu.px-mega-menu {
        width: 100%; }
      .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item {
        padding: 0.4rem 1rem; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl .offcanvas-header {
    border-bottom: 1px solid var(--bs-gray-300); }
  .navbar-expand-xl > .container {
    position: relative; }
  .navbar-expand-xl .navbar-nav > .nav-item {
    border-bottom: 1px solid var(--bs-gray-300); }
    .navbar-expand-xl .navbar-nav > .nav-item > .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .navbar-expand-xl .dropdown-menu {
    border: none;
    border-left: 1px solid var(--bs-gray-300);
    padding: 0 0 0 5px;
    margin: 0 0 15px;
    border-radius: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    --bs-navbar-padding-y: 0; }
    .navbar-expand-xl > .container {
      position: relative; }
    .navbar-expand-xl .px-dropdown-toggle {
      display: none; }
    .navbar-expand-xl .navbar-nav .nav-link {
      white-space: nowrap;
      --bs-nav-link-color: var(--bs-dark);
      --bs-nav-link-font-weight: 400;
      --bs-nav-link-padding-y: 1.4rem;
      --bs-navbar-nav-link-padding-x: 0.7rem; }
    .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .nav-link:after {
      content: "\f282";
      display: inline-block;
      font-family: bootstrap-icons !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: -.125em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: currentColor;
      font-size: 75%;
      margin-left: 7px;
      border: none; }
    .navbar-expand-xl .navbar-nav > .nav-item.dropdown.dropdown-full {
      position: static; }
    .navbar-expand-xl .navbar-nav > .nav-item:hover > .dropdown-menu {
      opacity: 1;
      pointer-events: auto; }
    .navbar-expand-xl .navbar-nav .px-mega-dropdown {
      position: static; }
    .navbar-expand-xl .navbar-nav .dropdown-mega-menu {
      width: 100%; }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: ease all 0.35s;
      margin: 0;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: 0px 10px 15px -3px rgba(var(--bs-dark-rgb), 0.05), 0px 4px 6px -4px rgba(var(--bs-dark-rgb), 0.05);
      border-top: 2px solid #B96F4A; }
      .navbar-expand-xl .navbar-nav .dropdown-menu.start {
        left: 0;
        transform: translate(0, 0); }
      .navbar-expand-xl .navbar-nav .dropdown-menu.end {
        left: auto;
        right: 0;
        transform: translate(0, 0); }
      .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
        position: relative;
        padding-right: 18px; }
        .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
          content: "\f285";
          display: inline-block;
          font-family: bootstrap-icons !important;
          font-style: normal;
          font-weight: normal !important;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          vertical-align: -.125em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: currentColor;
          font-size: 85%;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 10px;
          height: 10px; }
      .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
        opacity: 1;
        pointer-events: auto; }
      .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-menu-sub {
        position: absolute;
        top: 0;
        left: 100%;
        opacity: 0;
        pointer-events: none;
        transform: translate(0, 0); }
        .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-menu-sub.end {
          left: auto;
          right: 100%; }
      .navbar-expand-xl .navbar-nav .dropdown-menu.dropdown-menu-md {
        min-width: 22rem; }
      .navbar-expand-xl .navbar-nav .dropdown-menu.dropdown-menu-lg {
        min-width: 35rem; }
      .navbar-expand-xl .navbar-nav .dropdown-menu.dropdown-menu-xl {
        min-width: 42rem; }
      .navbar-expand-xl .navbar-nav .dropdown-menu.px-mega-menu {
        width: 100%; }
      .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-item {
        padding: 0.4rem 1rem; } }

@media (max-width: 1399.98px) {
  .navbar-expand-xxl .offcanvas-header {
    border-bottom: 1px solid var(--bs-gray-300); }
  .navbar-expand-xxl > .container {
    position: relative; }
  .navbar-expand-xxl .navbar-nav > .nav-item {
    border-bottom: 1px solid var(--bs-gray-300); }
    .navbar-expand-xxl .navbar-nav > .nav-item > .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .navbar-expand-xxl .dropdown-menu {
    border: none;
    border-left: 1px solid var(--bs-gray-300);
    padding: 0 0 0 5px;
    margin: 0 0 15px;
    border-radius: 0; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    --bs-navbar-padding-y: 0; }
    .navbar-expand-xxl > .container {
      position: relative; }
    .navbar-expand-xxl .px-dropdown-toggle {
      display: none; }
    .navbar-expand-xxl .navbar-nav .nav-link {
      white-space: nowrap;
      --bs-nav-link-color: var(--bs-dark);
      --bs-nav-link-font-weight: 400;
      --bs-nav-link-padding-y: 1.4rem;
      --bs-navbar-nav-link-padding-x: 0.7rem; }
    .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .nav-link:after {
      content: "\f282";
      display: inline-block;
      font-family: bootstrap-icons !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: -.125em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: currentColor;
      font-size: 75%;
      margin-left: 7px;
      border: none; }
    .navbar-expand-xxl .navbar-nav > .nav-item.dropdown.dropdown-full {
      position: static; }
    .navbar-expand-xxl .navbar-nav > .nav-item:hover > .dropdown-menu {
      opacity: 1;
      pointer-events: auto; }
    .navbar-expand-xxl .navbar-nav .px-mega-dropdown {
      position: static; }
    .navbar-expand-xxl .navbar-nav .dropdown-mega-menu {
      width: 100%; }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: ease all 0.35s;
      margin: 0;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: 0px 10px 15px -3px rgba(var(--bs-dark-rgb), 0.05), 0px 4px 6px -4px rgba(var(--bs-dark-rgb), 0.05);
      border-top: 2px solid #B96F4A; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu.start {
        left: 0;
        transform: translate(0, 0); }
      .navbar-expand-xxl .navbar-nav .dropdown-menu.end {
        left: auto;
        right: 0;
        transform: translate(0, 0); }
      .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
        position: relative;
        padding-right: 18px; }
        .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
          content: "\f285";
          display: inline-block;
          font-family: bootstrap-icons !important;
          font-style: normal;
          font-weight: normal !important;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          vertical-align: -.125em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: currentColor;
          font-size: 85%;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 10px;
          height: 10px; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
        opacity: 1;
        pointer-events: auto; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown-menu-sub {
        position: absolute;
        top: 0;
        left: 100%;
        opacity: 0;
        pointer-events: none;
        transform: translate(0, 0); }
        .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown-menu-sub.end {
          left: auto;
          right: 100%; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu.dropdown-menu-md {
        min-width: 22rem; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu.dropdown-menu-lg {
        min-width: 35rem; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu.dropdown-menu-xl {
        min-width: 42rem; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu.px-mega-menu {
        width: 100%; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown-item {
        padding: 0.4rem 1rem; } }

.navbar-expand {
  --bs-navbar-padding-y: 0; }
  .navbar-expand .offcanvas-header {
    border-bottom: 1px solid var(--bs-gray-300); }
  .navbar-expand > .container {
    position: relative; }
  .navbar-expand .navbar-nav > .nav-item {
    border-bottom: 1px solid var(--bs-gray-300); }
    .navbar-expand .navbar-nav > .nav-item > .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .navbar-expand .dropdown-menu {
    border: none;
    border-left: 1px solid var(--bs-gray-300);
    padding: 0 0 0 5px;
    margin: 0 0 15px;
    border-radius: 0; }
  .navbar-expand > .container {
    position: relative; }
  .navbar-expand .px-dropdown-toggle {
    display: none; }
  .navbar-expand .navbar-nav .nav-link {
    white-space: nowrap;
    --bs-nav-link-color: var(--bs-dark);
    --bs-nav-link-font-weight: 400;
    --bs-nav-link-padding-y: 1.4rem;
    --bs-navbar-nav-link-padding-x: 0.7rem; }
  .navbar-expand .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f282";
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    font-size: 75%;
    margin-left: 7px;
    border: none; }
  .navbar-expand .navbar-nav > .nav-item.dropdown.dropdown-full {
    position: static; }
  .navbar-expand .navbar-nav > .nav-item:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto; }
  .navbar-expand .navbar-nav .px-mega-dropdown {
    position: static; }
  .navbar-expand .navbar-nav .dropdown-mega-menu {
    width: 100%; }
  .navbar-expand .navbar-nav .dropdown-menu {
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: ease all 0.35s;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0px 10px 15px -3px rgba(var(--bs-dark-rgb), 0.05), 0px 4px 6px -4px rgba(var(--bs-dark-rgb), 0.05);
    border-top: 2px solid #B96F4A; }
    .navbar-expand .navbar-nav .dropdown-menu.start {
      left: 0;
      transform: translate(0, 0); }
    .navbar-expand .navbar-nav .dropdown-menu.end {
      left: auto;
      right: 0;
      transform: translate(0, 0); }
    .navbar-expand .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
      position: relative;
      padding-right: 18px; }
      .navbar-expand .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
        content: "\f285";
        display: inline-block;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: -.125em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: currentColor;
        font-size: 85%;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 10px;
        height: 10px; }
    .navbar-expand .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
      opacity: 1;
      pointer-events: auto; }
    .navbar-expand .navbar-nav .dropdown-menu .dropdown-menu-sub {
      position: absolute;
      top: 0;
      left: 100%;
      opacity: 0;
      pointer-events: none;
      transform: translate(0, 0); }
      .navbar-expand .navbar-nav .dropdown-menu .dropdown-menu-sub.end {
        left: auto;
        right: 100%; }
    .navbar-expand .navbar-nav .dropdown-menu.dropdown-menu-md {
      min-width: 22rem; }
    .navbar-expand .navbar-nav .dropdown-menu.dropdown-menu-lg {
      min-width: 35rem; }
    .navbar-expand .navbar-nav .dropdown-menu.dropdown-menu-xl {
      min-width: 42rem; }
    .navbar-expand .navbar-nav .dropdown-menu.px-mega-menu {
      width: 100%; }
    .navbar-expand .navbar-nav .dropdown-menu .dropdown-item {
      padding: 0.4rem 1rem; }

.navbar-transparent.navbar-light .navbar-toggler .toggler-icon {
  color: #fff; }

.navbar-transparent.navbar-light .header-navbar-brand .logo-dark {
  display: none; }

.navbar-transparent.navbar-light .header-navbar-brand .logo-light {
  display: block; }

.navbar-transparent.navbar-light .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1); }
  .navbar-transparent.navbar-light .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"); }

.navbar-transparent.headroom--not-top.navbar-light .navbar-toggler .toggler-icon {
  color: #212529; }

.navbar-transparent.headroom--not-top.navbar-light .header-navbar-brand .logo-dark {
  display: block; }

.navbar-transparent.headroom--not-top.navbar-light .header-navbar-brand .logo-light {
  display: none; }

@media (min-width: 576px) {
  .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-sm .navbar-nav .nav-link {
    color: rgba(var(--bs-white-rgb), 0.85); }
    .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-sm .navbar-nav .nav-link:hover {
      color: rgba(var(--bs-white-rgb), 1); } }

@media (min-width: 768px) {
  .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-md .navbar-nav .nav-link {
    color: rgba(var(--bs-white-rgb), 0.85); }
    .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-md .navbar-nav .nav-link:hover {
      color: rgba(var(--bs-white-rgb), 1); } }

@media (min-width: 992px) {
  .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-lg .navbar-nav .nav-link {
    color: rgba(var(--bs-white-rgb), 0.85); }
    .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-lg .navbar-nav .nav-link:hover {
      color: rgba(var(--bs-white-rgb), 1); } }

@media (min-width: 1200px) {
  .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-xl .navbar-nav .nav-link {
    color: rgba(var(--bs-white-rgb), 0.85); }
    .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-xl .navbar-nav .nav-link:hover {
      color: rgba(var(--bs-white-rgb), 1); } }

@media (min-width: 1400px) {
  .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-xxl .navbar-nav .nav-link {
    color: rgba(var(--bs-white-rgb), 0.85); }
    .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand-xxl .navbar-nav .nav-link:hover {
      color: rgba(var(--bs-white-rgb), 1); } }

.navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand .navbar-nav .nav-link {
  color: rgba(var(--bs-white-rgb), 0.85); }
  .navbar-transparent.navbar-light:not(.headroom--not-top) .navbar-expand .navbar-nav .nav-link:hover {
    color: rgba(var(--bs-white-rgb), 1); }

.footer-title-01 {
  margin: 0 0 20px;
  font-weight: 600; }

.footer-link-01 li + li {
  padding-top: 0.4rem; }

.footer-link-01 a {
  position: relative;
  display: inline-block;
  vertical-align: top; }
  .footer-link-01 a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: auto;
    right: 0;
    width: 0px;
    height: 1px;
    transition: ease all 0.35s;
    background: currentColor; }
  .footer-link-01 a:hover {
    --bs-text-opacity: 1; }
    .footer-link-01 a:hover:after {
      left: 0;
      right: auto;
      width: 100%; }

.loading-preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11111;
  background-color: var(--bs-white); }
  .loading-preloader .spinner-border {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }

.effect-section {
  position: relative;
  overflow: hidden; }
  .effect-section .svg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none; }
  .effect-section .svg-style-top-right {
    position: absolute;
    top: -5vw;
    right: -5vw;
    width: 30vw;
    height: 30vw; }
  .effect-section .svg-style-bottom-left {
    position: absolute;
    bottom: -5vw;
    left: -5vw;
    width: 25vw;
    height: 25vw;
    pointer-events: none; }
  .effect-section svg path {
    fill: currentColor; }

.laptop-screen {
  position: relative; }
  .laptop-screen .laptop-screen-inner {
    position: absolute;
    width: 79.3%;
    left: 10.5%;
    top: 7.6%;
    height: 80.5%;
    border: 1px solid var(--bs-gray-300);
    border-radius: 10px;
    overflow: hidden; }
    .laptop-screen .laptop-screen-inner img {
      object-fit: cover; }

.mobile-screen {
  position: relative;
  border: 10px solid var(--bs-gray-300);
  border-radius: 28px; }
  .mobile-screen .mobile-hidden {
    opacity: 0;
    visibility: hidden; }
  .mobile-screen .mobile-screen-in {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    overflow: hidden;
    border-radius: 18px; }
    .mobile-screen .mobile-screen-in img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

body,
html {
  width: 100%;
  height: 100%; }

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 100%;
  position: relative; }
  .wrapper > * {
    flex: 0 0 auto; }
  .wrapper > main {
    flex: 1 0 auto; }

.btn:hover, .btn:focus, .btn:active, .btn.active, .btn.show {
  box-shadow: 0 0.25rem 0.25rem 0.125rem rgba(var(--bs-btn-focus-shadow-rgb), 0.1), 0 0.375rem 0.75rem -0.125rem rgba(var(--bs-btn-focus-shadow-rgb), 0.4); }

.card {
  box-shadow: var(--bs-card-box-shadow); }

.bg-cover {
  background-size: cover; }

.bg-right-center {
  background-position: center right; }

.bg-no-repeat {
  background-repeat: no-repeat; }

.bg-center {
  background-position: center; }

@media (min-width: 992px) {
  .bg-fixed {
    background-attachment: fixed; } }

.bg-gray-g {
  background: linear-gradient(0deg, rgba(var(--bs-white-rgb), 0), rgba(var(--bs-gray-100-rgb), 1) 100%) !important; }

.bg-gray-g-reverse {
  background: linear-gradient(0deg, rgba(var(--bs-gray-100-rgb), 1), rgba(var(--bs-white-rgb), 0) 100%) !important; }

.bg-black-g {
  background-image: linear-gradient(transparent, var(--bs-black)); }

.section {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  @media (min-width: 768px) {
    .section {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  @media (min-width: 992px) {
    .section {
      padding-top: 5rem;
      padding-bottom: 5rem; } }
  @media (min-width: 1200px) {
    .section {
      padding-top: 6.25rem;
      padding-bottom: 6.25rem; } }

img {
  max-width: 100%;
  height: auto; }

.object-fit {
  object-fit: cover; }

.mw-auto {
  max-width: inherit; }

.grayscale {
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1); }

.icon-sm {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 75%;
  line-height: normal; }

.icon {
  width: 2.2rem;
  height: 2.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  line-height: 1; }

.icon-md {
  width: 2.8rem;
  height: 2.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1; }

.icon-lg {
  width: 3.5rem;
  height: 3.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1; }

.icon-xl {
  width: 5rem;
  height: 5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2.2rem;
  line-height: 1; }

.shadow-xs {
  box-shadow: 0px 1px 2px rgba(var(--bs-dark-rgb), 0.05) !important; }

.shadow-xl {
  box-shadow: 0px 20px 25px -5px rgba(var(--bs-dark-rgb), 0.02), 0px 8px 10px -6px rgba(var(--bs-dark-rgb), 0.05) !important; }

.shadow-xxl {
  box-shadow: 0px 25px 50px -12px rgba(var(--bs-dark-rgb), 0.05) !important; }

.section-heading {
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .section-heading {
      margin-bottom: 2rem; } }
  @media (min-width: 992px) {
    .section-heading {
      margin-bottom: 2.25rem; } }

.sm-title-1 {
  position: relative;
  padding-bottom: 0.8rem; }
  .sm-title-1:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 40px;
    height: 2px;
    background: var(--bs-primary); }

.avatar {
  height: 3.125rem;
  width: 3.125rem;
  position: relative;
  display: inline-block !important; }
  .avatar .avatar-status {
    border-radius: 50%;
    bottom: 10%;
    height: 20%;
    position: absolute;
    right: 10%;
    width: 20%;
    border: 1px solid var(--px-gray-200);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1; }
  .avatar .avatar-text {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 600;
    text-transform: uppercase; }

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover; }
  .avatar-img.contain {
    object-fit: contain; }

.avatar-group {
  padding: 0;
  display: flex; }
  .avatar-group .avatar-img,
  .avatar-group .avatar-text {
    border: 2px solid #fff; }
  .avatar-group .avatar {
    position: relative;
    transition: ease all 0.35s;
    transform: scale(1); }
    .avatar-group .avatar + .avatar {
      margin-left: -1rem; }
    .avatar-group .avatar:hover {
      z-index: 1;
      transform: scale(1.08); }
  .avatar-group .avatar-xs + .avatar-xs {
    margin-left: -.6125rem; }
  .avatar-group .avatar-xs .avatar-status {
    font-size: 8px; }
  .avatar-group .avatar-sm + .avatar-sm {
    margin-left: -.875rem; }
  .avatar-group .avatar-lg + .avatar-lg {
    margin-left: -1.5rem; }
  .avatar-group .avatar-xl + .avatar-xl {
    margin-left: -2rem; }
  .avatar-group .avatarxxl + .avatarxxl {
    margin-left: -2.5rem; }
  .avatar-group .avatar-xxxl + .avatar-xxxl {
    margin-left: -3rem; }

.avatar .avatar-name {
  margin-left: 7px; }

.avatar-xxs {
  height: 1rem;
  width: 1rem;
  position: relative; }
  .avatar-xxs .avatar-text {
    font-size: 0.5rem; }

.avatar-xs {
  height: 1.75rem;
  width: 1.75rem;
  position: relative; }
  .avatar-xs .avatar-text {
    font-size: 0.6rem; }

.avatar-sm {
  height: 2.6875rem;
  width: 2.6875rem;
  position: relative; }
  .avatar-sm .avatar-text {
    font-size: 0.8125rem; }

.avatar-lg {
  height: 4.25rem;
  width: 4.25rem;
  position: relative; }
  .avatar-lg .avatar-text {
    font-size: 1.25rem; }

.avatar-xl {
  height: 5.625rem;
  width: 5.625rem;
  position: relative; }
  .avatar-xl .avatar-text {
    font-size: 2rem; }

.avatar-xxl {
  height: 7rem;
  width: 7rem;
  position: relative; }
  .avatar-xxl .avatar-text {
    font-size: 3rem; }

.avatar-xxxl {
  width: 8rem;
  height: 8rem;
  position: relative; }
  .avatar-xxxl .avatar-text {
    font-size: 3.5rem; }

.breadcrumb-light {
  font-size: 0.85rem; }
  .breadcrumb-light .breadcrumb-item {
    color: rgba(var(--bs-white-rgb), 0.85); }
    .breadcrumb-light .breadcrumb-item a {
      color: rgba(var(--bs-white-rgb), 1); }
    .breadcrumb-light .breadcrumb-item + .breadcrumb-item:before {
      color: rgba(var(--bs-white-rgb), 0.7); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.custom-checkbox {
  position: relative;
  /*color*/
  /*Color*/ }
  .custom-checkbox .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: 1.7rem; }
    .custom-checkbox .custom-control-label:before {
      content: "";
      position: absolute;
      top: .1875rem;
      left: 0;
      display: block;
      width: 1.125rem;
      height: 1.125rem;
      pointer-events: none;
      background-color: var(--bs-white);
      box-shadow: 0px 0px 0px 2px var(--bs-white), 0px 0px 0px 3px var(--bs-gray-400); }
    .custom-checkbox .custom-control-label:after {
      content: "";
      position: absolute;
      border-top: 2px solid var(--bs-white);
      border-right: 2px solid var(--bs-white);
      width: 9px;
      height: 5px;
      top: -4px;
      left: 4px;
      z-index: 1;
      bottom: 0;
      margin: auto;
      transform: rotate(136deg);
      opacity: 0; }
  .custom-checkbox .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.125rem;
    height: 1.3125rem;
    opacity: 0; }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
      background-color: var(--bs-primary);
      box-shadow: 0px 0px 0px 2px var(--bs-white), 0px 0px 0px 3px var(--bs-primary); }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
      opacity: 1; }
    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label, .custom-checkbox .custom-control-input[disabled] ~ .custom-control-label {
      text-decoration: line-through; }
      .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before, .custom-checkbox .custom-control-input[disabled] ~ .custom-control-label:before {
        border-color: var(--bs-gray-200);
        box-shadow: 0px 0px 0px 2px var(--bs-white), 0px 0px 0px 3px var(--bs-gray-200); }
  .custom-checkbox.checkbox-color .custom-control-label:before {
    background-color: currentColor;
    border-radius: 50%; }
  .custom-checkbox.checkbox-color .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.125rem;
    height: 1.3125rem;
    opacity: 0; }
    .custom-checkbox.checkbox-color .custom-control-input:checked ~ .custom-control-label:before {
      background-color: currentColor; }
    .custom-checkbox.checkbox-color .custom-control-input:checked ~ .custom-control-label:after {
      opacity: 1; }
    .custom-checkbox.checkbox-color .custom-control-input:disabled ~ .custom-control-label, .custom-checkbox.checkbox-color .custom-control-input[disabled] ~ .custom-control-label {
      text-decoration: none; }
      .custom-checkbox.checkbox-color .custom-control-input:disabled ~ .custom-control-label span, .custom-checkbox.checkbox-color .custom-control-input[disabled] ~ .custom-control-label span {
        text-decoration: line-through; }

.form-switch.lg {
  min-height: 1.7em; }
  .form-switch.lg .form-check-input {
    height: 1.7em; }

.radio-text {
  position: relative;
  padding: 0; }
  .radio-text .radio-text-label {
    min-width: 2rem;
    height: 2rem;
    font-size: .75rem;
    border: 1px solid var(--bs-gray-300);
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    line-height: 1; }
  .radio-text .form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    margin: 0; }
    .radio-text .form-check-input:checked ~ .radio-text-label {
      border-color: var(--bs-primary);
      color: var(--bs-white);
      background-color: var(--bs-primary); }
  .radio-text.large .radio-text-label {
    min-width: 2.85rem;
    height: 2.85rem; }

.radio-color {
  position: relative;
  padding: 0;
  vertical-align: top;
  font-size: 0; }
  .radio-color .radio-color-label {
    width: 1.25rem;
    height: 1.25rem;
    font-size: .75rem;
    border: 1px solid var(--bs-gray-300);
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    position: relative; }
    .radio-color .radio-color-label span {
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border-radius: 50%; }
  .radio-color .form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    margin: 0; }
    .radio-color .form-check-input:checked ~ .radio-color-label {
      border-color: var(--bs-primary);
      color: var(--bs-primary); }
  .radio-color.large .radio-color-label {
    width: 2.85rem;
    height: 2.85rem; }

.cart-qty {
  width: 120px;
  position: relative; }
  .cart-qty .qty-btn {
    position: absolute;
    top: 3px;
    bottom: 3px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    background: rgba(var(--bs-primary-rgb), 0.1);
    color: var(--bs-primary); }
    .cart-qty .qty-btn:selection {
      background: transparent; }
    .cart-qty .qty-btn:hover {
      background: rgba(var(--bs-primary-rgb), 0.95);
      color: var(--bs-white); }
  .cart-qty .dec {
    left: 3px; }
  .cart-qty .inc {
    right: 3px; }
  .cart-qty .cart-qty-input {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    border: 1px solid var(--bs-gray-300); }

.px-file-upload {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .px-file-upload input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0; }
  .px-file-upload label {
    width: 100%;
    margin: 0;
    padding: 40px 50px;
    border: 2px dashed var(--bs-gray-200);
    text-align: center; }

.px-avatar-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0; }

.px-avatar-upload .avatar-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer; }
  .px-avatar-upload .avatar-preview .avatar-preview-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover; }

.form-switch.lg {
  min-height: 1.7em; }
  .form-switch.lg .form-check-input {
    height: 1.7em;
    width: 3em;
    border-radius: 3rem; }

.link-effect {
  position: relative;
  padding: 0;
  border: none;
  background: none; }
  .link-effect:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: auto;
    width: 100%;
    height: 1px;
    background: currentColor;
    transition: ease all 0.35s; }
  .link-effect:hover:after {
    width: 0;
    right: 0;
    left: auto; }

.link-style-01 {
  display: flex;
  max-width: 100%;
  overflow-y: auto;
  padding-bottom: 10px; }
  .link-style-01 a {
    padding: 0.6rem 1rem;
    color: var(--bs-dark);
    font-weight: 500;
    margin-right: 0.4rem;
    white-space: nowrap;
    border: 1px solid var(--bs-gray-300); }
    .link-style-01 a:hover, .link-style-01 a.active {
      background: var(--bs-primary);
      border-color: var(--bs-primary);
      color: var(--bs-white); }

.link-style-02 {
  display: flex;
  flex-wrap: wrap; }
  .link-style-02 a {
    padding: 0.6rem 1rem;
    background: var(--bs-gray-100);
    color: var(--bs-dark);
    border-radius: 5px;
    font-weight: 500;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem; }
    .link-style-02 a:hover, .link-style-02 a.active {
      background: var(--bs-primary);
      box-shadow: 0px 1px 3px rgba(var(--bs-dark-rgb), 0.04), 0px 1px 2px -1px rgba(var(--bs-dark-rgb), 0.05);
      color: var(--bs-white); }

.link-style-03 li + li {
  padding-top: 3px; }

.link-style-03 li a {
  font-size: 14px;
  color: #6C757D;
  font-weight: 400;
  position: relative;
  background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
  background-size: 0px 1px;
  background-position: 0px 95%;
  background-repeat: no-repeat;
  transition: ease all 0.35s; }
  .link-style-03 li a:hover {
    color: #B96F4A;
    background-size: 100% 1px; }

.cd-menu-link a {
  padding: 0.4rem 0.5rem;
  background: var(--bs-white);
  color: var(--bs-gray-700);
  font-size: 0.95rem; }
  .cd-menu-link a i {
    margin-right: 0.8rem; }
  .cd-menu-link a.active, .cd-menu-link a:hover {
    background: rgba(var(--bs-primary-rgb), 0.1);
    color: var(--bs-primary); }

.cd-menu-link-02 a {
  padding: 0.4rem 0.5rem;
  background: var(--bs-white);
  color: var(--bs-gray-700);
  display: inline-flex;
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 0.95rem;
  font-weight: 500; }
  .cd-menu-link-02 a i {
    margin-right: 0.8rem; }
  .cd-menu-link-02 a.active, .cd-menu-link-02 a:hover {
    background: rgba(var(--bs-primary-rgb), 0.1);
    color: var(--bs-primary); }

.hover-top {
  transition: ease-in-out all 0.35s;
  transform: translateY(0); }
  .hover-top:hover {
    transform: translateY(-3px); }

.hover-top-in .hover-top--in {
  transition: ease-in-out all 0.35s;
  position: relative;
  top: 0; }

.hover-top-in:hover .hover-top--in {
  top: -10px; }

.hover-scale .hover-scale-in {
  overflow: hidden; }
  .hover-scale .hover-scale-in img {
    transition: ease all 0.35s;
    transform: scale(1); }

.hover-scale:hover .hover-scale-in img {
  transform: scale(1.05); }

.hover-opacity-1 .hover-opacity-in {
  opacity: 0;
  transition: ease all 0.35s; }

.hover-opacity-1:hover .hover-opacity-in {
  opacity: 1; }

.shadow-sm-hover:hover {
  box-shadow: 0 12px 15px rgba(var(--bs-gray-700-rgb), 0.1) !important; }

.shadow-hover:hover {
  box-shadow: 0 0.375rem 1.5rem 0 rgba(var(--bs-gray-700-rgb), 0.125) !important; }

.shadow-lg-hover:hover {
  box-shadow: 0 10px 40px 10px rgba(var(--bs-gray-700-rgb), 0.175) !important; }

.dropdown-menu .dropdown-item {
  border-radius: 0.25rem; }

.cd-height {
  height: var(--cd-height); }

@media (max-width: 767.98px) {
  .cd-md-height {
    height: var(--cd-md-height); } }

.ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.ellipsis-4 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; }

mark, .mark {
  color: inherit;
  padding: 0;
  background: 0 0;
  background-image: linear-gradient(120deg, rgba(var(--bs-warning-rgb), 0.4) 0, rgba(var(--bs-warning-rgb), 0.4) 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.3em;
  background-position: 0 80%;
  position: relative; }

.accordion-style-01 .accordion .accordion-item {
  color: var(--bs-body-color); }
  .accordion-style-01 .accordion .accordion-item .accordion-button {
    background: none;
    padding: 1rem 0;
    font-weight: 500;
    color: var(--bs-dark); }
    .accordion-style-01 .accordion .accordion-item .accordion-button:not(.collapsed) {
      color: var(--bs-primary);
      outline: none;
      box-shadow: none; }

.accordion-style-01 .accordion .accordion-body {
  padding-left: 0;
  padding-top: 0; }

.tab-style-01 .nav-tabs {
  border: none; }
  .tab-style-01 .nav-tabs .nav-item + .nav-item {
    padding-left: 20px; }
  .tab-style-01 .nav-tabs .nav-link {
    padding: 0;
    border: none;
    font-weight: 600;
    color: var(--bs-body-color);
    position: relative; }
    .tab-style-01 .nav-tabs .nav-link:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0px;
      height: 2px;
      background: var(--bs-primary);
      transition: ease all 0.35s; }
    .tab-style-01 .nav-tabs .nav-link:hover {
      color: var(--bs-primary); }
    .tab-style-01 .nav-tabs .nav-link.active {
      color: var(--bs-dark); }
      .tab-style-01 .nav-tabs .nav-link.active:after {
        width: 100%; }

article h4, article .h4,
article h5,
article .h5 {
  margin-bottom: 1.2rem; }
  article h4:not(:first-child), article .h4:not(:first-child),
  article h5:not(:first-child),
  article .h5:not(:first-child) {
    margin-top: 1.5rem; }

article figure {
  margin: 3rem 0; }
  article figure + h4, article figure + .h4,
  article figure + h5,
  article figure + .h5 {
    margin-top: 0 !important; }

article blockquote {
  margin: 3rem 0; }
  article blockquote + h4, article blockquote + .h4,
  article blockquote + h5,
  article blockquote + .h5 {
    margin-top: 0 !important; }

:root {
  --swiper-theme-color: #B96F4A; }

.swiper-arrow-style-01 {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  color: var(--bs-gray-100);
  z-index: 1;
  text-align: center;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  transition: ease all 0.35s;
  font-size: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--bs-white); }
  .swiper-arrow-style-01.swiper-button-disabled {
    opacity: 0.5 !important;
    pointer-events: none; }
  .swiper-arrow-style-01:hover {
    background: var(--bs-primary);
    color: var(--bs-white); }
  .swiper-hover-arrow .swiper-arrow-style-01 {
    opacity: 0; }
  .swiper-hover-arrow:hover .swiper-arrow-style-01 {
    opacity: 1; }
  .swiper-arrow-style-01.swiper-prev {
    left: 10px;
    right: auto; }

.swiper-arrow-style-02 {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: var(--bs-gray-100);
  color: var(--bs-white);
  z-index: 1;
  text-align: center;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: ease all 0.35s;
  font-size: 20px;
  width: 25px;
  height: 40px; }
  .swiper-hover-arrow:hover .swiper-arrow-style-02.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none; }
  .swiper-arrow-style-02:hover {
    background: var(--bs-primary);
    color: var(--bs-white); }
  .swiper-hover-arrow .swiper-arrow-style-02 {
    opacity: 0; }
  .swiper-hover-arrow:hover .swiper-arrow-style-02 {
    opacity: 1; }
  .swiper-arrow-style-02.swiper-next {
    right: 0; }
  .swiper-arrow-style-02.swiper-prev {
    left: 0; }

.swiper-arrow-style-03 {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  color: var(--bs-white);
  background: var(--bs-dark);
  z-index: 1;
  text-align: center;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: 0;
  transition: ease all 0.35s;
  font-size: 18px;
  width: 33px;
  height: 33px;
  border-radius: 50%; }
  .swiper-arrow-style-03.swiper-button-disabled {
    opacity: 0.5 !important;
    pointer-events: none; }
    .swiper-hover-arrow .swiper-arrow-style-03.swiper-button-disabled {
      opacity: 0 !important; }
    .swiper-hover-arrow:hover .swiper-arrow-style-03.swiper-button-disabled {
      opacity: 0.5 !important; }
  .swiper-arrow-style-03:hover {
    background: var(--bs-primary);
    color: var(--bs-white); }
  .swiper-hover-arrow .swiper-arrow-style-03 {
    opacity: 0; }
  .swiper-hover-arrow:hover .swiper-arrow-style-03 {
    opacity: 1; }
  .swiper-arrow-style-03.swiper-prev {
    left: 0;
    right: auto; }
  .swiper-vertical .swiper-arrow-style-03 {
    margin: auto;
    width: 40px;
    height: 20px; }
    .swiper-vertical .swiper-arrow-style-03.swiper-next {
      bottom: auto;
      top: 0;
      left: 0;
      right: 0; }
    .swiper-vertical .swiper-arrow-style-03.swiper-prev {
      bottom: 0;
      top: auto;
      left: 0;
      right: 0; }

.pd-swiper-arrow {
  position: absolute;
  height: 25px;
  background: var(--bs-dark);
  color: var(--bs-white);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0; }
  .pd-swiper-arrow.swiper-next {
    bottom: 0;
    top: auto; }
  .pd-swiper-arrow.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none; }

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid var(--bs-primary);
  background: transparent;
  opacity: 1; }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination .swiper-pagination-bullet:hover {
    background: var(--bs-primary); }

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet {
  border: 1px solid var(--bs-white);
  background: transparent; }
  .swiper-pagination.swiper-pagination-white .swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination.swiper-pagination-white .swiper-pagination-bullet:hover {
    background: var(--bs-white); }

.swiper.swiper-no-scroll {
  overflow: inherit; }

.count-down-01 {
  display: flex; }
  .count-down-01 > div + div {
    padding-left: 10px;
    margin-left: 10px;
    position: relative; }
    .count-down-01 > div + div:before {
      content: ":";
      position: absolute;
      top: 0;
      left: 0; }

.pd-count-down .count-down {
  display: flex; }
  .pd-count-down .count-down > div {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    text-align: center; }
  .pd-count-down .count-down .count {
    min-height: 45px;
    width: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    font-weight: 500;
    color: var(--bs-dark); }
  .pd-count-down .count-down .title {
    font-size: 12px; }

.product-card {
  position: relative; }
  .product-card .product-card-sale {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem; }
  .product-card .product-card-media {
    position: relative;
    overflow: hidden; }
    .product-card .product-card-media img {
      transition: ease all 0.35s;
      transform: scale(1); }
    .product-card .product-card-media .product-media-hover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: ease all 0.25s; }
      .product-card .product-card-media .product-media-hover img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        opacity: 1;
        transform: scale(1.05); }
  .product-card .product-cart-btn {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 25px;
    opacity: 0;
    transition: ease all 0.25s; }
    .product-card .product-cart-btn .btn {
      width: 100%;
      padding: 8px 0;
      font-size: 13px; }
  .product-card .product-card-info {
    padding-top: 12px; }
  .product-card .product-card-btns {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column; }
    .product-card .product-card-btns .p-btn {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--bs-white);
      color: var(--bs-dark);
      padding: 0;
      font-size: 1.2rem; }
      .product-card .product-card-btns .p-btn:hover {
        background: var(--bs-primary);
        color: var(--bs-white); }
      .product-card .product-card-btns .p-btn + .p-btn {
        margin-top: 8px; }
  .product-card .product-card-rating {
    font-size: 0.75rem;
    color: var(--bs-warning); }
    .product-card .product-card-rating span {
      color: var(--bs-body-color); }
  .product-card .product-card-brand {
    font-size: 0.65rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    color: var(--bs-gray-600);
    line-height: normal; }
  .product-card .product-card-title {
    padding-bottom: 0; }
    .product-card .product-card-title a {
      color: var(--bs-dark);
      font-weight: 600; }
  .product-card .product-card-price span {
    color: var(--bs-dark);
    font-weight: 500; }
  .product-card .product-card-price .pp-offer {
    color: var(--bs-danger);
    font-size: 80%; }
  .product-card .product-card-price del {
    font-size: 80%; }
  .product-card:hover .product-card-media img {
    transform: scale(1.06); }
  .product-card:hover .product-card-media .product-media-hover {
    opacity: 1;
    transform: scale(1); }
  .product-card:hover .product-cart-btn {
    bottom: 15px;
    opacity: 1; }

@media (min-width: 992px) {
  .shop-sidebar {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
    flex: 0 0 280px;
    max-width: 280px; }
  .account-side-menu {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; } }

.shop-top-bar .layout-change .btn {
  background: var(--bs-white);
  width: 40px;
  height: 40px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  color: var(--bs-dark);
  justify-content: center;
  font-size: 1rem;
  border: 1px solid var(--bs-gray-300);
  box-shadow: 0px 1px 2px rgba(var(--bs-dark-rgb), 0.05); }
  .shop-top-bar .layout-change .btn.active, .shop-top-bar .layout-change .btn:hover {
    background: var(--bs-primary);
    color: var(--bs-white);
    border: 1px solid var(--bs-primary); }

.shop-top-bar .shortby-dropdown .btn {
  background: var(--bs-white);
  height: 40px;
  padding: 0 1rem;
  display: inline-flex;
  align-items: center;
  color: var(--bs-dark);
  justify-content: center;
  font-size: 0.8rem;
  border: 1px solid var(--bs-gray-300);
  box-shadow: 0px 1px 2px rgba(var(--bs-dark-rgb), 0.05); }

.shop-category-list .nav .nav {
  padding-left: 10px; }
  .shop-category-list .nav .nav .nav-link {
    color: var(--bs-body-color);
    font-size: 15px; }
    .shop-category-list .nav .nav .nav-link.active, .shop-category-list .nav .nav .nav-link:hover {
      color: var(--bs-primary); }

.shop-category-list .nav-item {
  position: relative; }
  .shop-category-list .nav-item .s-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    right: 0; }
    .shop-category-list .nav-item .s-icon:before, .shop-category-list .nav-item .s-icon:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background: #212529; }
    .shop-category-list .nav-item .s-icon:before {
      width: 2px;
      height: 10px; }
    .shop-category-list .nav-item .s-icon:after {
      width: 10px;
      height: 2px; }
    .shop-category-list .nav-item .s-icon[aria-expanded="true"]:before {
      height: 0; }

.shop-category-list .nav-link {
  padding: 4px 0;
  color: var(--bs-body-color); }
  .shop-category-list .nav-link span {
    color: var(--bs-body-color);
    font-size: 13px; }
  .shop-category-list .nav-link.active, .shop-category-list .nav-link:hover {
    color: var(--bs-primary); }

.shop-sidebar-block + .shop-sidebar-block {
  margin-top: 40px; }

.shop-sidebar-block .shop-sidebar-title {
  border-bottom: 1px solid var(--bs-gray-300);
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px; }
  .shop-sidebar-block .shop-sidebar-title h5, .shop-sidebar-block .shop-sidebar-title .h5 {
    font-size: 1rem;
    margin: 0;
    text-transform: uppercase; }
    .shop-sidebar-block .shop-sidebar-title h5:after, .shop-sidebar-block .shop-sidebar-title .h5:after {
      content: "";
      position: absolute;
      width: 30px;
      left: 0;
      bottom: -2px;
      height: 3px;
      background: var(--bs-primary); }

.shop-sidebar-block .nav-thumbs {
  display: flex;
  flex-wrap: wrap; }
  .shop-sidebar-block .nav-thumbs .form-check {
    width: calc(100% / 5);
    margin: 0;
    padding-right: 5px;
    padding-bottom: 5px; }
    .shop-sidebar-block .nav-thumbs .form-check .radio-text-label {
      min-width: 100%;
      height: 3rem; }

.product-detail .pd-rating {
  color: var(--bs-warning); }

.product-detail .pd-price .price {
  font-size: 1.4rem;
  color: var(--bs-dark);
  font-weight: 500; }

.product-detail .pd-price .pp-offer {
  color: var(--bs-danger); }

.product-detail-collapse {
  margin-top: 20px;
  border-top: 1px solid var(--bs-gray-300); }
  .product-detail-collapse .pd-collapse-row {
    border-bottom: 1px solid var(--bs-gray-300); }
    .product-detail-collapse .pd-collapse-row .pd-collapse-h5 {
      padding: 10px 0;
      font-weight: 600;
      color: #212529;
      display: flex; }
      .product-detail-collapse .pd-collapse-row .pd-collapse-h5 i {
        margin-left: auto; }
      .product-detail-collapse .pd-collapse-row .pd-collapse-h5[aria-expanded="false"] i:before {
        content: "\f282"; }
  .product-detail-collapse .pd-collapse-box {
    padding: 15px 0;
    border-top: 1px solid var(--bs-gray-300); }

.product-gallery {
  display: flex;
  margin-bottom: auto; }
  .product-gallery .product-slider {
    flex: 0 0 calc(100% - 120px);
    margin-left: 20px;
    max-width: calc(100% - 120px);
    order: 2;
    border: 1px solid #e6e6e6; }
    @media (max-width: 767.98px) {
      .product-gallery .product-slider {
        flex: 0 0 calc(100% - 60px);
        margin-left: 10px;
        max-width: calc(100% - 60px); } }
  .product-gallery .gallery-link {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(33, 37, 41, 0.8); }
  .product-gallery .product-thumb .swiper-slide img {
    border: 1px solid #e6e6e6;
    cursor: pointer;
    width: 100%; }
  .product-gallery .product-thumb .swiper-slide.swiper-slide-thumb-active img {
    border-color: #B96F4A; }
  .product-gallery .product-thumb {
    flex: 0 0 100px;
    max-width: 100px; }
    @media (max-width: 767.98px) {
      .product-gallery .product-thumb {
        flex: 0 0 50px;
        max-width: 50px; } }
    .product-gallery .product-thumb .slick-arrow {
      border: 1px solid #e6e6e6; }

.pd-title {
  font-weight: 600;
  color: var(--bs-dark); }
